import React from 'react';

import { AliasToken } from 'antd/es/theme/interface';

interface ThemeConfig {
	token: Partial<AliasToken>;
}

const data: Partial<AliasToken> = {
	borderRadius: 6,
	colorPrimary: '#fba10d',
};

export const theme: ThemeConfig = {
	token: {
		colorPrimary: data.colorPrimary,
		borderRadius: data.borderRadius,
		colorInfo: data.colorPrimary,
		colorInfoText: data.colorPrimary,
	},
};
