import * as React from 'react';
import { NavLink } from 'react-router-dom';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

const year = new Date().getFullYear();

const Footer: React.FC = () => {
	return <footer className="site-footer">
		<div className="container row-content">
			<div className="site-footer__copyright">
				©
				{' '}
				{year}
				{' '}
				NorthernTuna, all rights reserved
			</div>
			<div className="text-center site-footer__links">
				<div className="left-side">
					<NavLink className="ml10 mr10 site-footer__link" to="/privacy-policy">Privacy&nbsp;Policy</NavLink>
					<a
						className="ml10 mr10 site-footer__link"
						href="https://portal.northerntuna.com/"
						target="_blank"
						rel="noreferrer"
					>
						Providers&nbsp;Portal
					</a>
					<NavLink className="ml10 mr10 site-footer__link" to="/contact-support">Technical&nbsp;support</NavLink>
				</div>
			</div>
			<div className="nt-logo">
				<ImageLazy height={35} src="/amp-assets/images/nt-logo.png" alt="Northern Tuna Logo" />
			</div>
		</div>
	</footer>;
};

export default Footer;
