import React from 'react';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

const PortalLoader: React.FC = () => {
	return <LogoLoaderPage />;
};

export const params = {
	fallback: <PortalLoader />,
};

export default PortalLoader;
