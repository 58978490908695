import React from 'react';

function useServerEffect(callback) {
	React.useMemo(() => {
		if (typeof window === 'undefined') {
			callback();
		}
	}, []);
}

export default useServerEffect;
