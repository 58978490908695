import * as React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

interface Props {
	prefix: string;
	path: string;
	className?: string;
}

const WithPathLink: React.FC<Props> = ({
	prefix, path, children, className,
}) => {
	return <>
		{path
			? <LinkWithPrevLocation className={className} to={prefix ? `/${prefix}/${path}` : `/${path}`}>
				{children}
			</LinkWithPrevLocation>
			: <>
				{children}
			</>
		}
	</>;
};

export default WithPathLink;
