import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'antd/lib/button';
import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Room from '@common/react/components/UI/VideoChat/Room';
import { TypeKeys } from '@common/react/store/VideoChat';

const CallSound = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CallSound" */ '@common/react/components/UI/VideoChat/CallSound')), { fallback: <></> });

interface VideoChatProps {
	isMinimized?: boolean;
	isFullscreen?: boolean;
	setFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
	withMessages?: boolean;
	showMessages?: boolean;
	setShowMessages?: React.Dispatch<React.SetStateAction<boolean>>;
	controls?: React.ReactNode;
}

interface VideoChatRoomState {
	isMute: boolean;
	isCameraOff: boolean;
	isShareScreen: boolean;
}

const VideoChat: React.FC<VideoChatProps> = ({
	isMinimized, isFullscreen, setFullscreen, ...rest
}) => {
	const dispatch = useDispatch();

	const token = useSelector((state: any) => state.videoChat.token);
	const videoChatState = useSelector((state: any) => state.videoChat);
	const [loading, setLoading] = React.useState(false);

	const [videoChatRoomState, setVideoChatRoomState] = React.useState<VideoChatRoomState>({
		isMute: true,
		isCameraOff: !videoChatState.preventCameraStart,
		isShareScreen: false,
	});
	const { setShowMessages, withMessages, showMessages } = rest;

	React.useEffect(() => {
		setVideoChatRoomState({ ...videoChatRoomState, isCameraOff: !videoChatState.preventCameraStart });
	}, [videoChatState.preventCameraStart]);

	const setShareScreen = (value: boolean) => {
		setVideoChatRoomState((state) => ({ ...state, isShareScreen: value }));
	};

	const handleShareClick: React.MouseEventHandler = React.useCallback((e) => {
		e.preventDefault();
		setVideoChatRoomState((state) => ({ ...state, isShareScreen: !state.isShareScreen }));
	}, []);

	const handleFullscreenClick: React.MouseEventHandler = React.useCallback((e) => {
		e.preventDefault();
		setFullscreen && setFullscreen((state) => !state);
	}, []);

	const handleCameraClick: React.MouseEventHandler = React.useCallback((e) => {
		e.preventDefault();
		setVideoChatRoomState((state) => ({ ...state, isCameraOff: !state.isCameraOff }));
	}, []);

	const handleMuteClick: React.MouseEventHandler = React.useCallback((e) => {
		e.preventDefault();
		setVideoChatRoomState((state) => ({ ...state, isMute: !state.isMute }));
	}, []);

	const handleClose: React.MouseEventHandler = React.useCallback((e) => {
		e.preventDefault();

		dispatch({ type: TypeKeys.CLEAR });
	}, []);

	React.useEffect(() => {
		setLoading(videoChatState.visibility && !videoChatState.hasError);
	}, [videoChatState.visibility, videoChatState.hasError]);

	return <>
		{videoChatState.visibility && !videoChatState.hasError && loading && <CallSound />}
		<div>
			{
				(token && videoChatState.roomIdentifier)
					? (
						<Room
							onError={() => setLoading(false)}
							token={token}
							roomIdentifier={videoChatState.roomIdentifier}
							isMinimized={isMinimized}
							isCameraOff={videoChatRoomState.isCameraOff}
							isFullscreen={isFullscreen}
							isShareScreen={videoChatRoomState.isShareScreen}
							isMute={videoChatRoomState.isMute}
							setShareScreen={setShareScreen}
							preventCameraStart={videoChatState.preventCameraStart}
							onFirstConnect={() => setLoading(false)}
						/>
					)
					: !loading && !videoChatState.hasError && <div>No Room</div>
			}
		</div>
		<div className="controls">
			{rest.controls}
			{showMessages ? <Button
				title={withMessages ? 'Show messages' : 'Hide messages'}
				className="btn btn-primary"
				style={{
					color: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: withMessages ? 'green' : undefined,
					borderColor: withMessages ? 'green' : undefined,
				}}
				icon={<i style={{ fontSize: '1.5rem' }} className="fa fa-comment" />}
				onClick={() => setShowMessages && setShowMessages((prev) => !prev)}
			/> : null}
			{
				videoChatState.isSupport
				|| <Button
					title={videoChatRoomState.isShareScreen ? 'Finish screen sharing' : 'Share screen'}
					className="btn btn-primary"
					style={{
						color: 'white',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: videoChatRoomState.isShareScreen ? 'red' : undefined,
						borderColor: videoChatRoomState.isShareScreen ? 'red' : undefined,
					}}
					icon={<i style={{ fontSize: '1.5rem' }} className="fa fa-share-square" />}
					onClick={handleShareClick}
					// disabled={videoChatRoomState.isShareScreen}
				/>
			}
			<Button
				title="Full screen"
				className="btn btn-primary"
				style={{
					color: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: isFullscreen ? 'green' : undefined,
					borderColor: isFullscreen ? 'green' : undefined,
				}}
				icon={<i style={{ fontSize: '3rem' }} className="fa fa-border" />}
				onClick={handleFullscreenClick}
			/>
			{
				videoChatState.isSupport
				|| <Button
					title="Camera on/off"
					className="btn btn-primary"
					style={{
						color: 'white',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: videoChatRoomState.isCameraOff ? undefined : 'red',
						borderColor: videoChatRoomState.isCameraOff ? undefined : 'red',
					}}
					icon={<i style={{ fontSize: '1.5rem' }} className="fa fa-video-camera" />}
					onClick={handleCameraClick}
				/>
			}
			<Button
				title="Mute"
				className="btn btn-primary"
				style={{
					color: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: videoChatRoomState.isMute ? undefined : 'red',
					borderColor: videoChatRoomState.isMute ? undefined : 'red',
				}}
				icon={videoChatRoomState.isMute
					? <i style={{ fontSize: '1.5rem' }} className="fa fa-microphone" />
					: <i style={{ fontSize: '1.5rem' }} className="fa fa-microphone-slash" />}
				onClick={handleMuteClick}
			/>
			<Button
				title="Leave"
				className="btn btn-primary"
				style={{
					color: 'white',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'red',
					borderColor: 'red',
				}}
				icon={<i style={{ fontSize: '1.5rem' }} className="fa fa-phone" />}
				onClick={handleClose}
			/>
		</div>
	</>;
};

export default VideoChat;
