import React from 'react';

import Message from 'antd/lib/message';

import '@common/react/scss/components/roomMember.scss';

interface RoomMemberProps {
	member: any;
	muted?: boolean;
	visible?: boolean;
	isLocal?: boolean;
}

interface Visuals {
	hasScreenShare: boolean;
	isVisible: boolean;
	isMuted: boolean;
}

const trackpubsToTracks = (trackMap) => Array.from(trackMap.values())
	.map((publication: any) => publication.track)
	.filter((track) => track !== null);

const RoomMember: React.FC<RoomMemberProps> = ({
	member, muted, isLocal, visible = true,
}) => {
	const [visuals, setVisuals] = React.useState<Visuals>({
		hasScreenShare: false,
		isVisible: true,
		isMuted: muted ?? false,
	});

	const [currentTrack, setCurrentTrack] = React.useState<number>(0);
	const [videoTracks, setVideoTracks] = React.useState<Array<any>>([]);
	const [audioTracks, setAudioTracks] = React.useState<Array<any>>([]);

	const videoRef = React.useRef<HTMLVideoElement>(null);
	const audioRef = React.useRef<HTMLAudioElement>(null);

	const handleEvents = React.useCallback((track) => {
		if (track.kind === 'video') {
			track.on('disabled', () => {
				setVisuals({ ...visuals, isVisible: false });
			});

			track.on('enabled', () => {
				setVisuals({ ...visuals, isVisible: true });
			});
		}

		if (track.kind === 'audio') {
			track.on('disabled', () => {
				setVisuals({ ...visuals, isMuted: true });
			});

			track.on('enabled', () => {
				setVisuals({ ...visuals, isMuted: false });
			});
		}
	}, []);

	React.useEffect(() => {
		const trackSubscribed = (track) => {
			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => [...videoTracks, track]);
			} else {
				setAudioTracks((audioTracks) => [...audioTracks, track]);
			}
		};

		const trackUnsubscribed = (track) => {
			if (track.kind === 'video') {
				setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
			} else {
				setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
			}
		};

		setVideoTracks(trackpubsToTracks(member.videoTracks));
		setAudioTracks(trackpubsToTracks(member.audioTracks));

		member.on('trackSubscribed', trackSubscribed);
		member.on('trackUnsubscribed', trackUnsubscribed);

		member.tracks.forEach((publication) => {
			if (publication.isSubscribed) {
				handleEvents(publication.track);
			}
			publication.on('subscribed', handleEvents);
		});

		return () => {
			setVideoTracks([]);
			setAudioTracks([]);
			member.removeAllListeners();
		};
	}, [member]);

	React.useEffect(() => {
		setVideoTracks(trackpubsToTracks(member.videoTracks));
		setAudioTracks(trackpubsToTracks(member.audioTracks));

		member.tracks.forEach((publication) => {
			if (publication.isSubscribed) {
				handleEvents(publication.track);
			}
			publication.on('subscribed', handleEvents);
		});
	}, [`${Array.from(member?.tracks.keys()).map((key) => key)}`]);

	React.useEffect(() => {
		if (videoTracks.length < 2) {
			setCurrentTrack(0);
			setVisuals({ ...visuals, hasScreenShare: false });
		} else {
			setVisuals({ ...visuals, hasScreenShare: true });
		}

		const videoTrack = videoTracks[currentTrack];
		if (videoTrack) {
			videoTrack.attach(videoRef.current);
			return () => {
				videoTrack.detach();
			};
		}
	}, [videoTracks, currentTrack, setCurrentTrack]);

	React.useEffect(() => {
		const audioTrack = audioTracks[0];
		if (audioTrack) {
			audioTrack.attach(audioRef.current);
			return () => {
				audioTrack.detach();
			};
		}
	}, [audioTracks]);

	const isCameraOn = React.useMemo(() => {
		return videoTracks[0]?.isEnabled || isLocal;
	}, [videoTracks, visuals.isVisible]);

	const isAudioMuted = React.useMemo(() => {
		return !audioTracks[0]?.isEnabled;
	}, [audioTracks, visuals.isMuted]);

	const handleClickVideo: React.MouseEventHandler<HTMLVideoElement> = (e) => {
		e.preventDefault();

		if (videoTracks.length <= 1) return;
		setCurrentTrack((prevState) => (prevState === 0 ? 1 : 0));
	};

	const handleError: React.ReactEventHandler = (e) => {
		Message.error('Failed to play room member track');
	};

	return <div
		className="room-member"
		style={{
			display: isCameraOn && visible ? 'block' : 'none',
		}}
	>
		<div className="video-wrapper">
			{
				visuals.hasScreenShare && <div className="icon-wrapper screen-share">
					<i className="fa fa-border icon" />
				</div>
			}
			{
				isAudioMuted && <div className="icon-wrapper mute">
					<i className="fa fa-microphone-slash icon" />
				</div>
			}
			{/* eslint-disable */}
			<video
				title={member.identity}
				ref={videoRef}
				autoPlay
				onClick={handleClickVideo}
				onError={handleError}
			/>
			{/* eslint-enable */}
		</div>
		{/* eslint-disable */}
		<audio ref={audioRef} autoPlay muted={muted} />
		{/* eslint-enable */}
	</div>;
};

export default RoomMember;
