import * as React from 'react';

import Modal from 'antd/lib/modal';

import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';

import '@app/scss/components/specialModal.scss';
import { SpecialPageInner } from '@app/components/Pages/Specials/Special';

interface Props {
	id: number;
}

const SpecialModal: React.FC<Props> = ({ id }) => {
	const [open, setOpen] = React.useState<boolean>(false);

	const preventDefault = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return <div className="special-modal-page" onClick={(e) => preventDefault(e)}>
		<div className="special-modal-page__preview" onClick={handleOpen}>Fast Preview</div>
		<Modal
			width="800px"
			centered
			styles={{
				body: { height: '600px' },
			}}
			open={open}
			className="special-modal"
			onOk={handleClose}
			onCancel={handleClose}
			footer={null}
			destroyOnClose
		>
			<ItemProvider
				id={id}
				type="special"
				loadRequest="getSpecials"
			>
				<SpecialPageInner forPreview />
			</ItemProvider>
		</Modal>
	</div>;
};

export default SpecialModal;
