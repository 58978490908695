import React from 'react';
import ContentLoader from 'react-content-loader';

const PreviewImageLoader = (props) => (
	<ContentLoader
		width="100%"
		height="100%"
		viewBox="0 0 400 640"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
	</ContentLoader>
);

export default PreviewImageLoader;
