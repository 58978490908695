import React from 'react';
import { useCart } from 'react-use-cart';

import { getLocalStorageValue } from '@common/react/utils/localStorage/localStorage';

import { STORAGE_KEYS } from '@app/objects/StorageKeys';

const useShoppingCart = () => {
	const {
		removeItem, addItem, updateItemQuantity, getItem,
	} = useCart();
	const uid = getLocalStorageValue(STORAGE_KEYS.CHECKOUT_UID, null);

	const removeUid = () => {
		if (uid) {
			localStorage.removeItem(STORAGE_KEYS.CHECKOUT_UID);
		}
	};

	const removeCartItem = (itemId) => {
		removeItem(itemId);
		removeUid();
	};

	const addCartItem = (item, quantity) => {
		addItem(item, quantity);
		removeUid();
	};

	const updateCartItemQuantity = (itemId, quantity) => {
		updateItemQuantity(itemId, quantity);
		removeUid();
	};

	return {
		removeCartItem,
		addCartItem,
		updateCartItemQuantity,
		getItem,
	};
};

export default useShoppingCart;
