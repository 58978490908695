import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { loadable, loadableDelay } from '@common/react/loadable/loadableSettings';
import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

import { ApplicationState } from '@app/store';

const params = {
	fallback: <LogoLoaderPage />,
};

const DashboardLayout = loadable(() => loadableDelay(/* webpackChunkName: "DashboardLayout" */
	import('@app/components/Layouts/DashboardLayout/DashboardLayout'),
), params);

interface Props extends RouteProps {
	component: any;
	redirectPath?: string;
	title?: string;
}

const DashboardRoute: React.FC<Props> = ({
	component: Component, redirectPath = '/', title, ...rest
}) => {
	const user = useSelector((state: ApplicationState) => state.login.user, shallowEqual);
	return <Route
		{...rest}
		render={(props) => (user ? <DashboardLayout>
			{title && <Helmet>
				<title>{title}</title>
			</Helmet>}
			<ErrorBoundaryWithSentry>
				<RequestProvider>
					<Component {...props} />
				</RequestProvider>
			</ErrorBoundaryWithSentry>
		</DashboardLayout> : <Redirect to={redirectPath} />)}
	/>;
};

export default DashboardRoute;
