import * as React from 'react';
import { Switch } from 'react-router-dom';

import loadable from '@loadable/component';
import 'regenerator-runtime/runtime';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

import DashboardRoute from '@app/components/Routes/DashboardRoute';
import RouteWithFooter from '@app/components/Routes/RouteWithFooter';
import Layout from '@app/components/Layout';

const params = {
	fallback: <LogoLoaderPage />,
};

/* --------------Admin---------------*/
const Profile = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProfilePage" */
		'@app/components/Pages/Profile'
	)), params);
const Dashboard = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DashboardPage" */
		'@app/components/Pages/Admin/Dashboard/Dashboard'
	)), params);
const ChatsPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ChatsPage" */
		'@app/components/Pages/Admin/Chats/Chats'
	)), params);
const Questionnaires = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "QuestionnairesPage" */
		'@app/components/Pages/Admin/Invites/Questionnaires'
	)), params);
const ConsentForms = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConsentFormsPage" */
		'@app/components/Pages/Admin/Invites/ConsentForms'
	)), params);
const Instructions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "InstructionsPage" */
		'@app/components/Pages/Admin/Invites/Instructions'
	)), params);
const Analyzes = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AnalyzesPage" */
		'@app/components/Pages/Admin/Analyzes/Analyzes'
	)), params);
const Appointments = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentsPage" */
		'@app/components/Pages/Admin/Appointments/Appointments'
	)), params);
const AppointmentViewer = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AppointmentViewerPage" */
		'@app/components/Pages/Admin/Appointments/Appointment'
	)), params);
const Documents = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DocumentsPage" */
		'@app/components/Pages/Admin/Documents/Documents'
	)), params);
const Doctors = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SearchPage" */
		'@app/components/Pages/Doctors'
	)), params);
const Prescriptions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DocumentsPage" */
		'@app/components/Pages/Admin/Prescriptions/Prescriptions'
	)), params);
const Users = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Users" */
		'@app/components/Pages/Admin/Users/Users'
	)), params);
const ShortLinks = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ShortLinks" */
		'@app/components/Pages/Admin/ShortLinks/ShortLinks'
	)), params);
const AuthLinks = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AuthLinks" */
		'@common/react/components/Pages/AuthLinks/AuthLinks'
	)), params);
const AuthLinksFilters = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "AuthLinksFilters" */
		'@commonTuna/react/components/Pages/Admin/AuthLinks/AuthLinksFilters'
	)), params);
const UserSessions = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "UserSessions" */
		'@app/components/Pages/Admin/UserSessions/UserSessions'
	)), params);
const Notifications = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "NotificationsPage" */
		'@app/components/Pages/Admin/Notifications/Notifications'
	)), params);
const Pages = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PagesPage" */
		'@app/components/Pages/Admin/Pages/Pages'
	)), params);
const PageEditor = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageEditorPage" */
		'@app/components/Pages/Admin/Pages/PageEditor'
	)), params);
const Orders = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "OrdersPage" */
		'@app/components/Pages/Admin/Orders/Orders'
	)), params);
const Inquiries = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Inquiries" */
		'@app/components/Pages/Admin/Inquiries/Inquiries'
	)), params);
const Bills = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BillsPage" */
		'@app/components/Pages/Admin/Bills/Bills'
	)), params);
const Checkout = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CheckoutPage" */
		'@app/components/Pages/Admin/Checkout/Checkout'
	)), params);
const EmailTemplates = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmailTemplatesPage" */
		'@app/components/Pages/Admin/EmailTemplates/EmailTemplates'
	)), params);
const MarketingEmails = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "MarketingEmailsPage" */
		'@app/components/Pages/Admin/MarketingEmails/MarketingEmails'
	)), params);
const SmsLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "SmsLogs" */
			'@commonTuna/react/components/Pages/Admin/SmsLogs/SmsLogs'
		)),
	params,
);
const EmailLogs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "EmailLogs" */
			'@commonTuna/react/components/Pages/Admin/EmailLogs/EmailLogs'
		)),
	params,
);
const Ordering = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Ordering" */
		'@app/components/Pages/Admin/Ordering/Ordering'
	)), params);
const BaseHostedServices = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BaseHostedServicesPage" */
		'@app/components/Pages/Admin/BaseHostedServices/BaseHostedServices'
	)), params);
const PageAccesses = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageAccessesPage" */
		'@app/components/Pages/Admin/PageAccesses/PageAccesses'
	)), params);

/* ------------Admin end-------------*/

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Home" */
		'@app/components/Pages/Home/Home'
	)), params);
const Login = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "LoginPage" */
		'@app/components/Pages/Login/Login'
	)), params);
const Recover = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RecoverPage" */
		'@app/components/Pages/Recover/Recover'
	)), params);
const Confirm = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmPage" */
		'@app/components/Pages/Confirm/Confirm'
	)), params);
const ContactSupport = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ContactSupportPage" */
		'@app/components/Pages/ContactSupport/ContactSupport'
	)), params);
const Register = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegisterPage" */
		'@app/components/Pages/Register/Register'
	)), params);
const Confirmation = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ConfirmationPage" */
		'@app/components/Pages/Confirmation/Confirmation'
	)), params);
const ChangeEmailConfirmation = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ChangeEmailConfirmation" */
		'@app/components/Pages/Register/ChangeEmailConfirmation'
	)), params);
const CompanyPortalComponent = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PortalFromBBY" */
		'@app/components/Pages/FromBBY/CompanyPortal'
	)), params);
const DoctorPortalComponent = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorPortalComponent" */
		'@app/components/Pages/DoctorPortal'
	)), params);
const DoctorReview = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorReview" */
		'@app/components/Pages/DoctorReview'
	)), params);
const Specialties = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specialties" */
		'@app/components/Pages/Specialties/Specialties'
	)), params);
const Locations = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Locations" */
		'@app/components/Pages/Region/Regions'
	)), params);
const Location = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Region" */
		'@app/components/Pages/Region/Region'
	)), params);
const Specialty = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specialty" */
		'@app/components/Pages/Specialties/Specialty'
	)), params);
const Specials = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specials" */
		'@app/components/Pages/Specials/Specials'
	)), params);
const Special = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Special" */
		'@app/components/Pages/Specials/Special'
	)), params);
const Product = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Product" */
		'@app/components/Pages/Products/Product'
	)), params);
const Procedures = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Procedures" */
		'@app/components/Pages/Procedures/Procedures'
	)), params);
const ProcedurePage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ProcedurePage" */
		'@app/components/Pages/Procedures/ProcedurePage'
	)), params);
const PrivacyPolicy = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PrivacyPolicy" */
		'@app/components/Pages/PrivacyPolicy/PrivacyPolicy'
	)), params);
const RegistrationArticle = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "RegistrationArticle" */
		'@app/components/Pages/RegistrationArticle/RegistrationArticle'
	)), params);
const Inquiry = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Inquiry" */
		'@app/components/Pages/Inquiry/Inquiry'
	)), params);
const ExpiredLink = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ExpiredLink" */
		'@app/components/Pages/ExpiredLink/ExpiredLink'
	)), params);
const ShoppingCart = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ShoppingCart" */
		'@app/components/Pages/ShoppingCart/ShoppingCart'
	)), params);
const QuickPay = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "QuickPayPage" */
		'@app/components/Pages/QuickPay/QuickPay'
	)), params);
const ErrorPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ErrorPage" */ '@common/react/components/Pages/ErrorPage/ErrorPage')), params);
const PaymentsProcessing = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PaymentsProcessing" */
		'@app/components/Pages/PaymentsProcessing/PaymentsProcessing'
	)), params);
const Clinics = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicsPage" */
		'@app/components/Pages/Clinics/Clinics'
	)), params);
const ClinicPortalComponent = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicPortalComponent" */
		'@app/components/Pages/Clinics/ClinicPortalComponent'
	)), params);

export const routes = (<Layout>
	<Switch>
		<RouteWithFooter exact path="/" component={Home} />
		<RouteWithFooter path="/login" component={Login} />
		<RouteWithFooter path="/registration" component={Register} />
		<RouteWithFooter path="/confirmation" component={Confirmation} />
		<RouteWithFooter path="/recover" component={Recover} />
		<RouteWithFooter path="/confirm" component={Confirm} />
		<RouteWithFooter path="/change-email" component={ChangeEmailConfirmation} />

		<RouteWithFooter path="/doctors" component={Doctors} />
		<RouteWithFooter path="/doctor/:path?" component={DoctorPortalComponent} />
		<RouteWithFooter path="/doctor-review/:path?" component={DoctorReview} />
		<RouteWithFooter path="/contact-support" component={ContactSupport} />
		<RouteWithFooter path="/company/:path?" component={CompanyPortalComponent} />
		<RouteWithFooter path="/privacy-policy" component={PrivacyPolicy} />
		<RouteWithFooter path="/about-registration" component={RegistrationArticle} />
		<RouteWithFooter path="/specialties" component={Specialties} />
		<RouteWithFooter path="/locations" component={Locations} />
		<RouteWithFooter path="/location/:path" component={Location} />
		<RouteWithFooter path="/procedures" component={Procedures} />
		<RouteWithFooter path="/procedure/:path" component={ProcedurePage} />
		<RouteWithFooter path="/specialty/:path" component={Specialty} />
		<RouteWithFooter path="/inquiry" component={Inquiry} />
		<RouteWithFooter path="/specials" component={Specials} />
		<RouteWithFooter path="/special/:path" component={Special} />
		<RouteWithFooter path="/product/:path" component={Product} />
		<RouteWithFooter path="/shopping-cart" component={ShoppingCart} />
		<RouteWithFooter path="/ordering" component={Ordering} />
		<RouteWithFooter path="/expiredLink" component={ExpiredLink} />
		<RouteWithFooter path="/quick-pay" component={QuickPay} />
		<RouteWithFooter path="/payments-processing" component={PaymentsProcessing} />
		<RouteWithFooter path="/clinics" component={Clinics} />
		<RouteWithFooter path="/clinic/:path?" component={ClinicPortalComponent} />
		<DashboardRoute path="/profile" component={Profile} title="Profile" />
		<DashboardRoute path="/dashboard" component={Dashboard} />
		<DashboardRoute path="/chats" component={ChatsPage} title="Chats" />
		<DashboardRoute path="/questionnaires/:page?" component={Questionnaires} title="Questionnaires" />
		<DashboardRoute path="/consent-forms/:page?" component={ConsentForms} title="Consent|Medical Documents" />
		<DashboardRoute path="/instructions/:page?" component={Instructions} title="Patient Instructions" />
		<DashboardRoute path="/analysis-list/:page?" component={Analyzes} title="Lab. Orders" />
		<DashboardRoute path="/appointment-list/:page?" component={Appointments} title="Appointments" />
		<DashboardRoute path="/appointment/:id" component={AppointmentViewer} title="Appointment" />
		<DashboardRoute path="/document-list/:page?" component={Documents} title="Health Records" />
		<DashboardRoute path="/prescriptions-list/:page?" component={Prescriptions} title="Prescriptions" />
		<DashboardRoute path="/user-list/:page?" component={Users} title="Users" />
		<DashboardRoute path="/shortLink-list/:page?" component={ShortLinks} title="Short Links" />
		<DashboardRoute path="/authLink-list/:page?" component={(props) => <AuthLinks filters={<AuthLinksFilters />} />} title="Auth Links" />
		<DashboardRoute path="/user-session-list/:page?" component={UserSessions} title="Entry Log Journal" />
		<DashboardRoute path="/notifications/:page?" component={Notifications} title="Notifications" />
		<DashboardRoute path="/page-list/:page?" component={Pages} title="Pages" />
		<DashboardRoute path="/page-editor/:id" component={PageEditor} title="Page Editor" />
		<DashboardRoute path="/inquiry-list/:page?" component={Inquiries} title="Inquiries" />
		<DashboardRoute path="/orders/:page?" component={Orders} title="Purchases" />
		<DashboardRoute path="/bills/:page?" component={Bills} title="Bills" />
		<DashboardRoute path="/checkout/:uid?" component={Checkout} title="Checkout" />
		<DashboardRoute path="/email-templates" component={EmailTemplates} title="Email Templates" />
		<DashboardRoute path="/marketing-emails" component={MarketingEmails} title="Marketing Emails" />
		<DashboardRoute path="/smsLogs" component={SmsLogs} title="Sms Logs" />
		<DashboardRoute path="/emailLogs" component={EmailLogs} title="Email Logs" />
		<DashboardRoute path="/base-hosted-service-list/:page?" component={BaseHostedServices} title="Hosted Services" />
		<DashboardRoute path="/page-access-list/:page?" component={PageAccesses} title="Page Accesses" />
		<NotFoundRoute path="/error" title="Error" component={ErrorPage} loginRoute={DashboardRoute} openRoute={RouteWithFooter} />
		<NotFoundRoute path="*" loginRoute={DashboardRoute} openRoute={RouteWithFooter} />
	</Switch>
</Layout>);
