import * as React from 'react';

import { Chat } from '@common/react/components/Chat/Chat';

import { getChatName } from '@common/react/components/Chat/ChatUtils';
import {
	useChatSettingsProviderContext,
	getUserPick,
} from '@common/react/components/Chat/ChatSettingsProvider';

interface ChatItemProps {
	chat: Chat;
	userId: number;
	selectedChatId: number | null;
	onSelect: (chat: Chat) => void;
	getUser?: (chat, userId) => React.ReactNode;
}

export const ChatItem: React.FC<ChatItemProps> = ({
	chat, userId, selectedChatId, onSelect, getUser = getUserPick,
}) => {
	const lastMessage = chat.lastMessage;

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const { state: { plugins, listComponent } } = context;
	const name = getChatName(chat, userId);

	return <li className={`chat-list__item ${selectedChatId === chat.id ? 'chat-list__item_active' : ''}`} onClick={() => onSelect(chat)}>
		{getUser(chat, userId)}
		<div className={`chat-list__item-content ${chat.unviewedMessagesCount > 0 ? 'chat-list__item-content__padding' : ''}`}>
			<div className="chat-list__item-name" title={Array.isArray(name) ? name.join(' ') : name}>
				{name}
			</div>
			<React.Fragment key="lastMessage">
				{
					lastMessage ? plugins[lastMessage.chatMessageType]?.message?.lastMessage?.({
						message: lastMessage,
						chat,
						userId,
					}) : null
				}
			</React.Fragment>
			{listComponent?.map((plugin) => <React.Fragment key={plugin}>
				{plugins[plugin]?.listComponent?.({ chat })}
			</React.Fragment>)}
			{chat.unviewedMessagesCount > 0 && <div className="chat-list__item-count">{chat.unviewedMessagesCount}</div>}
		</div>
	</li>;
};
