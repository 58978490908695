import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';

import { MainLayout } from '@app/components/Layouts/MainLayout';

import Footer from '@app/components/UI/Footer/Footer';

interface Props extends RouteProps {
	component: any;
}

const RouteWithFooter: React.FC<Props> = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			<MainLayout>
				<ErrorBoundaryWithSentry>
					<Component {...props} />
					<Footer />
				</ErrorBoundaryWithSentry>
			</MainLayout>}
	/>
);

export default RouteWithFooter;
