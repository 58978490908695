import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';

import { getActionCreators } from '@common/react/store/Login';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

interface OwnProps {
	render?: (logout: (event: React.MouseEvent) => void) => JSX.Element;
	clearState?: boolean;
}

const Logout: React.FC<OwnProps> = ({ render, clearState }) => {
	const dispatch = useDispatch();
	const login = useSelector((state: BaseApplicationState<BaseUser>) => state.login, shallowEqual);
	const { logoff } = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), [login.user]);

	const logout = (event: React.MouseEvent) => {
		event.preventDefault();
		logoff(clearState);
	};

	return render ? render(logout) : <a onClick={logout} className="logout-component">Logout</a>;
};

export default Logout;
