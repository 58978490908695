import { getSignalR } from '@common/react/utils/configureSignarR';

export interface UpdateFields {
	id: number;
	type: string;
	values: any;
}

const notification = (args: any, handle: (notify: UpdateFields) => void, type: string, id?: number, field?: string) => {
	if (args && args.data && args.data.type === type) {
		if ((!id || args.data.id === id) && (!field || args.data.values[`${field}`] !== undefined)) {
			handle(args.data as UpdateFields);
		}
	}
};

export const subscribe = (handle: (notify: any) => void) => () => {
	getSignalR().then((connection) => connection.on('handle', handle));

	return () => {
		getSignalR().then((connection) => connection.off('handle', handle));
	};
};

export const subscribeOnType = (handle: (notify: UpdateFields) => void, type: string, id?: number, field?: string) => {
	return subscribe((args) => notification(args, handle, type, id, field))();
};
