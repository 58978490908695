import React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, NavLink } from 'react-router-dom';

import { bindActionCreators } from 'redux';

import Tag from 'antd/lib/tag';

import { parseQuery } from '@common/typescript/utils/url';
import { BaseParams } from '@common/react/objects/BaseParams';
import Logout from '@common/react/components/UI/Logout/Logout';
import { getUserName } from '@common/react/utils/utils';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';
import TransmutationBack from '@common/react/components/Forms/TransmutationBack';
import { getSearchParamsFromUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

import Avatar from '@commonTuna/react/components/UI/Avatar/Avatar';

import { ApplicationState } from '@app/store';
import { actionCreators } from '@app/store/HeaderSearch';
import DashboardTopAlert from '@app/components/UI/DashboardTopAlert/DashboardTopAlert';
import { SearchFilterState } from '@app/store/SearchFilter';
import ShoppingCartDropdown from '@app/components/UI/ShoppingCartDropdown/ShoppingCartDropdown';
import BurgerMenu from '@app/components/UI/Header/BurgerMenu';
import '@app/scss/pages/headerSearch.scss';
import { useMenuStateProviderContext } from '@app/components/UI/Menu/MenuStateProvider';
import LocationLink from '@app/components/UI/LocationLink/LocationLink';
import Search, { clearValues, emptyValues } from '@app/components/UI/Header/Search';
import { STORAGE_KEYS } from '@app/objects/StorageKeys';

const HeaderSearch: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const { user, transmuted } = useSelector((state: ApplicationState) => state.login, shallowEqual);
	const state = useSelector((state: ApplicationState) => state.header, shallowEqual);
	const searchFilterData = useSelector((state: ApplicationState) => state.searchFilterData, shallowEqual);
	const dispatch = useDispatch();
	const { setState } = bindActionCreators(actionCreators, dispatch);
	const context = useMenuStateProviderContext();
	const { state: { mounted }, actions: { setState: setMenuState } } = context;
	const locationData = useSelector((state: ApplicationState) => state.buildData?.item?.location, shallowEqual);

	const userName = user ? (user.firstName || user.lastName ? getUserName(user) : user.email) : '';
	const [keys, setKeys] = React.useState<any>({ key: 'initial' });

	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const toggle = () => setIsOpen(!isOpen);

	const [values, setValues] = React.useState<any>({
		locationName: '',
		globalPayerName: '',
		text: '',
		languages: [],
		certificates: [],
	});

	const handleUrl = (arr: Array<{ name: string, value: any}>) => {
		const searchObj = location.pathname === '/doctors' ? parseQuery(location.search)
			: { ...state, text: undefined };

		arr.forEach(({ name, value }) => {
			searchObj[name] = value instanceof Array ? `[${value}]` : `${value}`;
		});

		const emptyValues = ['', 'null', 'undefined', undefined, null, '-1', '[]'];

		const search = Object.keys(searchObj)
			.filter((k) => emptyValues.indexOf(searchObj[k]) === -1)
			.map((k) => `${k}=${searchObj[k]}`).join('&');

		if (location.pathname !== '/doctors') {
			history.push(`/doctors?${search}`);
		} else {
			history.replace(`${location.pathname.replace(/\/\d+/, '/1')}?${search}`);
		}
	};

	const onSelectSearch = (value, option) => {
		const obj = option.props.item;
		const update = (name, value) => {
			setState({
				...state,
				...emptyValues,
				[name]: value,
			});
			handleUrl([...clearValues, { name, value }]);
		};

		if (obj.doctor !== null) {
			update('doctorId', obj.doctor.id);
		} else if (obj.specialty !== null && obj.specialty !== undefined) {
			update('specialtyId', obj.specialty.id);
		} else if (obj.globalProcedure !== null) {
			update('globalProcedureId', obj.globalProcedure.id);
		} else if (obj.clinic !== null) {
			update('locationId', obj.clinic.id);
		} else if (obj.insurance !== null) {
			update('globalPayerId', obj.insurance.id);
		} else if (obj.profession !== null) {
			update('professionId', obj.profession.id);
		} else if (obj.special !== null) {
			setState({
				...state,
				...emptyValues,
			});
			history.push(`/special/${obj.special.path}`);
		} else if (obj.location !== null) {
			setState({
				...state,
				...emptyValues,
			});
			history.push(`/location/${obj.location.path}`);
		}
	};

	const handleSearchFilterResult = (searchObj: BaseParams, result: SearchFilterState) => {
		const text = searchObj.text;
		if (!text) {
			if (result.doctorName) {
				handleUrl([...clearValues, { name: 'doctorId', value: searchObj.doctorId }]);
			} else if (result.globalProcedureName) {
				handleUrl([...clearValues, { name: 'globalProcedureId', value: searchObj.globalProcedureId }]);
			} else if (result.specialtyName) {
				handleUrl([...clearValues, { name: 'specialtyId', value: searchObj.specialtyId }]);
			} else if (result.locationName) {
				handleUrl([...clearValues, { name: 'locationId', value: searchObj.locationId }]);
			}
		}

		setKeys((prev) => ({
			...prev,
			globalProcedureIds: Math.random(),
			specialtyIds: Math.random(),
		}));
		setState(searchObj);
	};

	React.useEffect(() => {
		let searchObj: BaseParams = {};

		if (location.pathname === '/doctors') {
			searchObj = getSearchParamsFromUrl(location);
		} else {
			searchObj = {
				doctorId: -1,
				specialtyId: -1,
				globalProcedureId: -1,
			};
		}

		if (searchObj.text) {
			handleUrl([
				...clearValues,
				{ name: 'text', value: searchObj.text },
			]);
			setValues((prev) => ({
				...prev,
				text: searchObj.text,
			}));
		}

		handleSearchFilterResult(searchObj, searchFilterData);
	}, []);

	React.useEffect(() => {
		let text = state.text;
		if (!text) {
			if (searchFilterData.doctorName) {
				text = `Doctor: ${searchFilterData.doctorName}`;
			} else if (searchFilterData.globalProcedureName) {
				text = `Procedure: ${searchFilterData.globalProcedureName}`;
			} else if (searchFilterData.specialtyName) {
				text = `Specialty: ${searchFilterData.specialtyName}`;
			} else if (searchFilterData.locationName) {
				text = `Location: ${searchFilterData.locationName}`;
			}
		}

		setValues((prev) => ({
			...prev,
			...searchFilterData,
			text: text || '',
		}));
	}, [searchFilterData]);

	React.useEffect(() => {
		// setIsOpen(false);
		setMenuState((prev) => ({ ...prev, open: false }));
	}, [history.location.pathname]);
	const showAlert = user && !user?.emailConfirmed && !!user.email;

	return (
		<>
			{user && showAlert ? <div className="dashboard-top-alert__wrapper">
				<DashboardTopAlert objectId={user.id} />
			</div> : null}
			<div className="search-header">
				<div className={`search-page search-page-content pull-to-end ${isOpen ? 'search_open' : 'search_close'}`}>
					<div className="logo">
						<NavLink to="/">
							<ImageLazy src="/amp-assets/images/logo.png" alt="Northern Tuna Logo" height="72px" width="150px" />
						</NavLink>
					</div>
					<div className="row search">
						{locationData ? <LocationLink className="search__location-tag" address={locationData?.addressEn}>
							<i className="fa fa-map-marker" />
&nbsp;
							{locationData.nameEn}
						</LocationLink> : null}
						<div className="search-container col-sm-12">
							<Search
								key={keys.key}
								value={values.text || ''}
								onSelect={onSelectSearch}
								onChange={(value = '') => {
									value === '' && setState({
										...state,
										text: value,
										...emptyValues,
									});
									setValues((prev) => ({
										...prev,
										text: value,
									}));
									handleUrl([
										...clearValues,
										{ name: 'text', value },
									]);
								}}
								initFocus={isOpen}
								onSearchClick={() => setIsOpen(false)}
								onCloseClick={() => {
									setState({
										...state,
										text: '',
										...emptyValues,
									});
									handleUrl([
										...clearValues,
										{ name: 'text', value: '' },
									]);
									setKeys((prev) => ({ ...prev, key: Math.random() }));
									setValues((prev) => ({ ...prev, text: '' }));
									if (!['doctorId', 'specialtyId', 'globalProcedureId', 'locationId', 'globalPayerId', 'professionId']
										.some((key) => state[key] && state[key] > 0) && !values.text) {
										setIsOpen(false);
									}
								}}
							/>
						</div>
						<div className="col-sm-12 bottom-menu__container mobile-hide">
							<div className="pull-left">
								<nav className="skew-menu">
									<ul>
										<li><NavLink className="" to="/doctors">Doctors</NavLink></li>
										<li><NavLink className="ml10" to="/procedures">Procedures</NavLink></li>
										<li><NavLink className="ml10" to="/specialties">Specialties</NavLink></li>
										<li><NavLink className="ml10" to="/locations">Locations</NavLink></li>
										<li><NavLink className="ml10" to="/clinics">Clinics</NavLink></li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					<div className="shopping-cart search-icon">
						<div className="shopping-cart__icon" onClick={() => toggle()}>
							<i className="fa fa-search" title="search" />
						</div>
					</div>
					<ShoppingCartDropdown withoutPopover />
					{user ? <div className="links-container">
						<div className="header-link mobile-hide btn btn-default">
							<NavLink to="/specials">Specials Shop</NavLink>
						</div>
						<div className="header-link mobile-hide btn btn-primary">
							<NavLink to="/doctors">Schedule an Appointment</NavLink>
						</div>
					</div> : null}
					{user
						? <div className="search-header-user-block">
							<ul className={`search-header-user-block__icons ${transmuted ? 'short' : ''}`}>
								{/*
							<li>
								<NavLink to="/profile"><i className="fa fa-user" title="Profile"/>
									{user && user.unviewedMessagesCount > 0 && <div className="badge search-header-user-block__badge">{user.unviewedMessagesCount}</div>}
								</NavLink>
							</li>
							*/}
								{/* <li title="Specials Shop" className="mobile-hide">
								<NavLink to="/specials">
									<i className="fa fa-cubes"/>
								</NavLink>
							</li>
							<li title="Schedule an Appointment" className="mobile-hide">
								<NavLink to="/doctors">
									<i className="fa fa-calendar-plus-o"/>
								</NavLink>
							</li>
							<li className="vertical-line hide-mobile"/> */}
								<li>
									<NavLink to="/chats">
										<i className="fa fa-comments" title="Chats" />
										{user && user.unviewedMessagesCount > 0 && (
											<div className="badge search-header-user-block__badge">
												{user.unviewedMessagesCount}
											</div>
										)}
									</NavLink>
								</li>
								<li>
									<NavLink to="/notifications">
										<i className="fa fa-bell" title="Notifications" />
										{user.unviewedNotificationsCount > 0 && (
											<div className="badge search-header-user-block__badge">
												{user.unviewedNotificationsCount}
											</div>
										)}
									</NavLink>
								</li>
								<TransmutationBack
									transmuted={transmuted}
									onSuccess={() => localStorage.removeItem(STORAGE_KEYS.CHECKOUT_UID)}
								/>
								<li className="mobile-hide">
									<Logout
										render={(logout) => <i onClick={logout} className="fa fa-power-off" title="Logout" />}
										clearState
									/>
								</li>
								<li className="avatar">
									{mounted
										? <a onClick={() => setMenuState((prev) => ({ ...prev, open: !prev.open }))}>
											<div className="search-header-avatar" title={userName}>
												<Avatar
													src={user.avatar}
													original={user.originalAvatar}
													color={user.color}
													firstName={user.firstName}
													lastName={user.lastName}
												/>
											</div>
										</a>
										: <NavLink to="/dashboard">
											<div className="search-header-avatar" title={userName}>
												<Avatar
													src={user.avatar}
													original={user.originalAvatar}
													color={user.color}
													firstName={user.firstName}
													lastName={user.lastName}
												/>
											</div>
										</NavLink>
									}
								</li>
							</ul>
						</div>
						: <div className="links-container">
							<div className="header-link mobile-hide btn btn-default">
								<NavLink to="/specials">Specials Shop</NavLink>
							</div>
							<div className="header-link mobile-hide btn btn-primary">
								<NavLink to="/doctors">Schedule an Appointment</NavLink>
							</div>
							<div className="header-link mobile-hide btn btn-default login">
								<NavLink to="/login" className="btn-link">Patient Login</NavLink>
							</div>
							<div className="search-header-user-block show-mobile show-mobile__block" style={{ padding: '0 6px' }}>
								<ul className="search-header-user-block__icons">
									<li>
										<NavLink to="/login"><i className="fa fa-user" /></NavLink>
									</li>
								</ul>
							</div>
						</div>
					}
					<BurgerMenu getPopupContainer={(node) => node.closest('.search-page') || document.body} />
					<div className="emr-link">
						<Tag color="orange">
							<NavLink to="/contact-support">
								Contact Us
							</NavLink>
						</Tag>
					&nbsp;
						<Tag color="orange">
							<a href="https://portal.northerntuna.com/" target="_blank" rel="noreferrer">
								EMR
							</a>
						</Tag>
					</div>
					<div className="line-1" />
				</div>
			</div>
		</>
	);
};

export default HeaderSearch;
