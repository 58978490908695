import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Button from 'antd/lib/button';
import Message from 'antd/lib/message';

import { TypeKeys } from '@common/react/store/VideoChat';
import { ChatRoomType } from '@common/react/components/Chat/Chat';
import useRequest from '@common/react/hooks/useRequest';

const shortBeeps = require('@common/react/audio/short-beeps.ogg');

interface VideoChatButtonProps {
	roomId: string;
	roomName: string;
	roomCreateActor: string;
	notifyUserIds: Array<number>;
	avatar?: string;
	chatId?: number;
	button?: (onClick: React.MouseEventHandler, isDisabled: boolean, loading?: boolean) => JSX.Element;
	support?: boolean;
	onClick?: () => void;
	isVideo?: boolean;
}

const VideoChatButton: React.FC<VideoChatButtonProps> = (props) => {
	const {
		button, support, roomId, roomName, notifyUserIds, roomCreateActor, chatId, onClick, avatar, isVideo = true,
	} = props;
	const dispatch = useDispatch();
	const isChatting = useSelector((state: any) => state.videoChat.isChatting, shallowEqual);
	const user = useSelector((state: any) => state.login.user, shallowEqual);
	const [loading, setLoading] = React.useState(false);
	const request = useRequest();

	const handleClick: React.MouseEventHandler = (e) => {
		e.preventDefault();
		setLoading(true);

		const handleSuccess = (res) => {
			dispatch({
				type: TypeKeys.UPDATE,
				data: {
					token: res.token,
					roomIdentifier: res.roomIdentifier,
					roomName,
					avatar,
					isSupport: support ?? false,
					hasError: false,
					chatId,
					preventCameraStart: !isVideo,
					visibility: true,
				},
			});
		};

		request<{token: string, roomIdentifier: string}>(roomCreateActor, {
			roomName: roomId,
			chatId,
			notifyUserIds,
			chatRoomType: isVideo ? ChatRoomType.Video : ChatRoomType.Audio,
		})
			.then(handleSuccess)
			.catch((e) => {
				Message.error(e);
				dispatch({ type: TypeKeys.CLEAR });
				if (user?.enableSounds) {
					try {
						new Audio(shortBeeps).play();
					} catch (e) {
						console.log(e);
					}
				}
			})
			.finally(() => {
				setLoading(false);
			});

		onClick && onClick();
	};

	return button
		? button(handleClick, isChatting, loading)
		: (
			<Button
				htmlType="button"
				shape="circle"
				loading={loading}
				onClick={handleClick}
				disabled={isChatting}
			>
				<i className="fa fa-video-camera" />
			</Button>
		);
};

export default VideoChatButton;
