import * as React from 'react';

import message from 'antd/lib/message';
import { FormikProps } from 'formik';

import { ChatFormButtonsProps, NewMessage, ChatPlugin } from '@common/react/components/Chat/Chat';
import File from '@common/react/components/Forms/Files/File';
import { FileType } from '@common/typescript/objects/FileInterface';

export const FilePlugin: ChatPlugin = {
	formButton: (formikBag: FormikProps<NewMessage>, props: ChatFormButtonsProps) => <React.Fragment key="file">
		<File
			multiple
			buttonCaption={<i className="fa fa-paperclip" style={{ fontSize: 16, margin: 0, verticalAlign: 'text-top' }} />}
			asButton
			objectId={-1}
			type="chatMessage"
			update={props.onUploadFile}
			onError={(err: any) => {
				message.error(typeof err === 'object' ? err.message : err);
			}}
			fileType={FileType.File}
			buttonClassName="btn btn-sm btn-default chat-message-item"
			showError={false}
		/>
	</React.Fragment>,
};
