import React from 'react';

const callSound = require('@common/react/audio/callSound.ogg');

const CallSound = () => {
	/* eslint-disable */
	return <audio src={callSound} loop autoPlay />;
	/* eslint-enable */
};

export default CallSound;
