import React from 'react';

import { FormikProps } from 'formik';

interface FormikRefProps {
	formikRef: React.MutableRefObject<FormikProps<any> | null>;
	formikBug: FormikProps<any>;
}

const FormikRef: React.FC<FormikRefProps> = ({ formikRef, formikBug }) => {
	React.useEffect(() => {
		if (formikRef) {
			formikRef.current = formikBug;
		}
	}, [formikBug]);

	return <></>;
};

export default FormikRef;
