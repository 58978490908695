import React from 'react';

import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';

import SpecialCard from '@app/components/Pages/Specials/SpecialCard';
import { Special } from '@app/objects/Special';

import '@app/scss/components/special.scss';
import '@app/scss/components/specialsCarousel.scss';

interface Props {
	specials: Array<Special>;
	count?: number;
	titleAsH2?: boolean;
}

const SpecialsCarousel: React.FC<Props> = ({ specials, count = 5, titleAsH2 }) => {
	const text = 'Popular Specials';

	return <div className="specials-carousel">
		{titleAsH2 ? <h2>{text}</h2> : <h1>{text}</h1>}
		<div className="special-list">
			<Swiper
				slidesPerView="auto"
				spaceBetween={12}
			>
				{specials.map((special) =>
					<SwiperSlide
						key={special.id}
					>
						<div className="specials-carousel__item">
							<SpecialCard item={special} lazyLoad />
						</div>
					</SwiperSlide>)}
			</Swiper>
		</div>
	</div>;
};

export default SpecialsCarousel;
