import React from 'react';
import { useSelector } from 'react-redux';

import Button from 'antd/lib/button';

import Draggable, { DraggablePlace } from '@common/react/components/UI/Draggable';
import '@common/react/scss/components/videoChatModal.scss';
import VideoChat from '@common/react/components/UI/VideoChat/VideoChat';
import ChatMessagesWithForm from '@common/react/components/Chat/ChatMessagesWithForm';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

interface VideoChatModal {
	skeletonImage?: string;
	defaultPlace?: DraggablePlace;
	dragContainerPadding?: number;
	avatarComponent?: (avatar?: string, userName?: string) => React.ReactNode;
}

const defaultAvatarComponent = (avatar) => {
	return <img src={avatar} alt="Avatar" />;
};

const VideoChatModal: React.FC<VideoChatModal> = (props) => {
	const {
		skeletonImage, defaultPlace = 'leftBottom', dragContainerPadding = 30, avatarComponent = defaultAvatarComponent,
	} = props;
	const videoChatState = useSelector((state: any) => state.videoChat);
	const context = useChatSettingsProviderContext();
	const showMessagesButtonInVideoModal = context?.state?.showMessagesButtonInVideoModal;

	const [isMinimized, setIsMinimized] = React.useState<boolean>(false);
	const [isFullscreen, setFullscreen] = React.useState<boolean>(false);
	const [withMessages, setWithMessages] = React.useState<boolean>(false);

	const handleMinimize: React.MouseEventHandler = (e) => {
		e.preventDefault();

		setIsMinimized(true);
	};

	const handleMaximize: React.MouseEventHandler = (e) => {
		e.preventDefault();

		setIsMinimized(false);
	};

	return (
		<Draggable
			className={`video-chat-modal video-chat-modal_${
				videoChatState.visibility ? 'visible' : 'hidden'} ${
				withMessages ? 'video-chat-modal__messages' : ''} ${
				isFullscreen ? 'video-chat-modal__fullscreen fullscreen' : ''} ${
				isMinimized ? 'video-chat-modal__min' : ''}`}
			isDraggable
			isInOrigin={isFullscreen}
			ignoreElements={['btn-video-chat-close', 'btn', 'chat-component-list-content', 'chat-form-component']}
			defaultPosition={defaultPlace}
			padding={dragContainerPadding}
		>
			<div className="video-chat-modal__inner">
				<div className="video-chat-header">
					<div className="video-chat-header__left">
						{avatarComponent(videoChatState.avatar || skeletonImage, videoChatState.roomName)}
						<span>{videoChatState.roomName}</span>
					</div>
				</div>

				{videoChatState.visibility && <div className="video-chat-main">
					<VideoChat
						isMinimized={isMinimized}
						isFullscreen={isFullscreen}
						setFullscreen={setFullscreen}
						setShowMessages={setWithMessages}
						withMessages={withMessages}
						showMessages={showMessagesButtonInVideoModal}
						controls={<Button
							title="Minimize"
							className="btn btn-primary minimize-button"
							style={{
								color: 'white',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: isMinimized ? 'green' : undefined,
								borderColor: isMinimized ? 'green' : undefined,
							}}
							icon={<i
								style={{ fontSize: '1.5rem' }}
								className={`fa fa-window-${!isMinimized ? 'minimize' : 'maximize'}`}
							/>}
							onClick={isMinimized ? handleMaximize : handleMinimize}
						/>}
					/>
				</div>}
			</div>
			{withMessages && showMessagesButtonInVideoModal && videoChatState.chatId ? <div className="video-chat-modal__messages-chat">
				<ChatMessagesWithForm
					actionsInPopup
					filesAfterButtons
					getActionPopupContainer={(node) => node.closest('.ant-popover-inner-content')}
					chatId={videoChatState.chatId}
				/>
			</div> : null}
		</Draggable>
	);
};

export default VideoChatModal;
