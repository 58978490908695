import * as React from 'react';

import Tooltip, { AbstractTooltipProps } from 'antd/lib/tooltip';

interface Props {
	text?: string;
	count: number;
	tooltipProps?: AbstractTooltipProps;
	className?: string;
	withTitle?: boolean;
}

const TextWithTooltip: React.FC<Props> = ({
	text, count, tooltipProps, className, withTitle,
}) => {
	return text ? <div className={className}>
		{text.length > count
			? <Tooltip title={text} placement="right" {...tooltipProps}>
				{text?.substr(0, count)}
				...
			</Tooltip>
			: withTitle ? <span title={text}>{text}</span> : text
		}
	</div> : null;
};

export default TextWithTooltip;
