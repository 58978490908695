import React from 'react';
import { CartProvider, useCart } from 'react-use-cart';

import { List } from '@common/typescript/objects/List';

import { request } from '@app/components/Api';
import { Special } from '@app/objects/Special';
import { transformSpecialToCartItem } from '@app/objects/CartItem';

const CartProviderWithUpdate: React.FC = ({ children }) => {
	const {
		items, updateItem, removeItem, isEmpty,
	} = useCart();

	const cartItemsIds = React.useMemo(() => items.map((item) => item.id), []);

	const updateCartItems = (specialsList: Array<Special>) => {
		if (!isEmpty) {
			if (cartItemsIds.length > specialsList.length) {
				const specialsIds = specialsList.map((special) => special.id.toString());
				const shouldDelete = cartItemsIds.filter((id) => !specialsIds.includes(id));

				for (let i = 0; i < shouldDelete.length; i++) {
					removeItem(shouldDelete[i]);
				}
			}

			for (let i = 0; i < cartItemsIds.length; i++) {
				const actualSpecial = specialsList.find((special) => special.id.toString() === cartItemsIds[i]);
				if (actualSpecial) {
					const specialForStore = transformSpecialToCartItem(actualSpecial);
					updateItem(actualSpecial.id.toString(), specialForStore);
				}
			}
		}
	};

	const firstLoad = () => {
		if (cartItemsIds.length === 0) return;

		request<List<Special>>('specialsList', {
			ids: cartItemsIds,
		})
			.then((res) => {
				updateCartItems(res.list);
			})
			.catch((err) => console.log(err));
	};

	React.useEffect(() => {
		firstLoad();
	}, []);

	return <>
		{children}
	</>;
};

const CartProviderWithUpdateWrapper: React.FC = ({ children }) => {
	return <CartProvider id="specials-shop">
		<CartProviderWithUpdate>
			{children}
		</CartProviderWithUpdate>
	</CartProvider>;
};

export default CartProviderWithUpdateWrapper;
