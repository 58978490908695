import { Action, ActionCreatorsMapObject, Reducer } from 'redux';

import { BaseAppThunkAction } from '@common/react/store';

import { SET_SEARCH_STATE } from '@app/store/constants';
import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store/index';

export interface HeaderState {
	doctorId?: number;
	globalProcedureId?: number;
	specialtyId?: number;
	globalPayerId?: number;
	text?: string;
	locationId?: number;
	languageIds?: Array<number>;
	from?: number | null;
	globalProcedureIds?: Array<number>;
	specialtyIds?: Array<number>;
	certificateIds?: Array<number>;
	regionIds?: Array<number>;
	locationIds?: Array<number>;
	globalPayerIds?: Array<number>;
	gender?: number;
}

interface SetAction extends Action {
	payload: HeaderState;
}

export interface HeaderActionCreators extends ActionCreatorsMapObject {
	setState: (state: HeaderState) => BaseAppThunkAction<SetAction, User, ApplicationState>;
}

export const actionCreators: HeaderActionCreators = {
	setState: (state: HeaderState) => (dispatch) => {
		dispatch({
			type: SET_SEARCH_STATE,
			payload: { ...state },
		});
	},
};

const initialState = {};

export const reducer: Reducer<HeaderState> = (state: HeaderState = initialState, action: Action) => {
	switch (action.type) {
		case SET_SEARCH_STATE:
			return { ...(action as SetAction).payload };
	}

	return state;
};
