import React from 'react';

interface Props {
	value: number;
	className?: string;
}

const Stars: React.FC<Props> = ({ value, className }) => {
	return <>
		{Array
			.from({ length: Math.round(value || 0) })
			.map((_, i) => i)
			.map((i) => <i className={`fa fa-star ${className}`} key={i} aria-hidden="true" />)}
	</>;
};

export default Stars;
