import * as React from 'react';

import Modal from 'antd/lib/modal';

import Loading from '@common/react/components/Core/LoadingProvider/Loader';
import { imageSize } from '@common/react/utils/utils';
import { IWithFile } from '@common/typescript/objects/WithFile';
import { FileInterface } from '@common/typescript/objects/FileInterface';
import ImageWithLoader from '@common/react/components/UI/ImageWithLoader';
import '@common/react/scss/components/colorbox.scss';

interface ComponentProps {
	files: Array<IWithFile> | Array<{ file: { src: string } }>;
	defaultIdx: number | null;
	onCancel: () => void;
	imageSizeName?: string;
	width?: string;
	className?: string;
	basePath?: string;
	alt?: string;
	withDownload?: boolean;
	zIndex?: number;
}

const Colorbox: React.FC<ComponentProps> = (props) => {
	const {
		files, defaultIdx, onCancel, imageSizeName, alt, basePath = '/',
		width = '80vw', className = '', withDownload = true, zIndex,
	} = props;
	const [currentIdx, setCurrentIdx] = React.useState<number | null>(null);
	const [file, setFile] = React.useState<FileInterface | { src: string }>();

	React.useEffect(() => {
		const buttonClick = (e) => {
			if (e.code === 'ArrowLeft') {
				handleLeftClick();
			} else if (e.code === 'ArrowRight') {
				handleRightClick();
			}
		};
		document.addEventListener('keydown', buttonClick);

		return () => {
			document.removeEventListener('keydown', buttonClick);
		};
	});

	React.useEffect(() => {
		setCurrentIdx(defaultIdx);
		if (defaultIdx !== null) {
			setFile(files[defaultIdx].file);
		}
	}, [defaultIdx]);

	const updateState = (index: number) => {
		setCurrentIdx(index);
		setFile(files[index].file);
	};

	const handleLeftClick = () => {
		if (currentIdx === null) return;
		const nextIndex = currentIdx === 0 ? files.length - 1 : currentIdx - 1;
		updateState(nextIndex);
	};

	const handleRightClick = () => {
		if (currentIdx === null) return;
		const nextIndex = currentIdx === files.length - 1 ? 0 : currentIdx + 1;
		updateState(nextIndex);
	};

	const download = file?.src && withDownload ? (<a
		className="avatar-component__download"
		href={`${basePath}${file.src}`}
		target="_blank"
		download
		title="Download"
		rel="noreferrer"
	>
		<i className="fa fa-download" />
	</a>) : null;

	const footer = React.useMemo(() => {
		return (files.length > 1)
			? [
				download,
				<span key="number" className="pull-left">
					{currentIdx ? currentIdx + 1 : 1}
					{' '}
					of
					{' '}
					{files.length}
				</span>,
				<button key="previous" type="button" className="btn btn-sm btn-default" aria-hidden="true" onClick={handleLeftClick}>
					<i className="fa fa-caret-left" />
				</button>,
				<button key="next" type="button" className="btn btn-sm btn-default" aria-hidden="true" onClick={handleRightClick}>
					<i className="fa fa-caret-right" />
				</button>,
			]
			: null;
	}, [currentIdx]);

	return <Modal
		title={null}
		open={defaultIdx !== null}
		onCancel={onCancel}
		destroyOnClose
		className={`colorbox ${className}`}
		wrapClassName="colorbox__wrapper"
		width={width}
		footer={footer}
		zIndex={zIndex}
	>
		<div className="is-relative" style={{ width: '100%', height: '100%' }}>
			{currentIdx !== null && file
				? <>
					<ImageWithLoader
						key={file.src}
						className="colorbox__image img-responsive"
						src={`${basePath}${imageSizeName ? imageSize(file.src, imageSizeName) : file.src}`}
						alt={alt}
					/>
					{files.length === 1 ? download : null}
				</>
				: <Loading />
			}
		</div>
	</Modal>;
};

export default Colorbox;
