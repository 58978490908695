import { Action, Reducer, ActionCreatorsMapObject } from 'redux';

import { Language } from '@commonTuna/react/objects/Language';
import { Certificate } from '@commonTuna/react/objects/Certificate';

import { AppThunkAction } from '@app/store/index';

export interface SearchFilterState {
	locationName: string;
	globalPayerName: string;
	specialtyName?: string;
	doctorName?: string;
	globalProcedureName?: string;
	globalProcedures?: Array<string>;
	specialties?: Array<string>;
	languages: Array<Language>;
	certificates: Array<Certificate>;
}

export enum SearchFilterTypeKeys {
	SET_SEARCH_FILTER_STATE = 'SET_SEARCH_FILTER_STATE',
	UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER'
}

export interface SetSearchFilterState {
	type: SearchFilterTypeKeys.SET_SEARCH_FILTER_STATE;
	payload: SearchFilterState;
}

interface UpdateSearchFilter {
	type: SearchFilterTypeKeys.UPDATE_SEARCH_FILTER;
	data: object;
}

export type Actions = SetSearchFilterState | UpdateSearchFilter;

export interface SearchFilterActionCreators extends ActionCreatorsMapObject {
	setSearchFilterState: (state: SearchFilterState) => AppThunkAction<Actions>;
	updateSearchFilter: (data: object) => AppThunkAction<Actions>;
}

export const searchFilterActionCreators = {
	setSearchFilterState: (state: SearchFilterState): AppThunkAction<Actions> => (dispatch, getState) => {
		dispatch({ type: SearchFilterTypeKeys.SET_SEARCH_FILTER_STATE, payload: { ...state } });
	},
	updateSearchFilter: (data: object): AppThunkAction<Actions> => (dispatch, getState) => {
		dispatch({ type: SearchFilterTypeKeys.UPDATE_SEARCH_FILTER, data });
	},
};

const initialState = {
	locationName: '',
	globalPayerName: '',
	specialtyName: '',
	doctorName: '',
	globalProcedureName: '',
	globalProcedures: [],
	specialties: [],
	languages: [],
	certificates: [],
};

export const reducer: Reducer<SearchFilterState> = (
	state: SearchFilterState = initialState,
	incomingAction: Action,
) => {
	const action = incomingAction as Actions;
	switch (action.type) {
		case SearchFilterTypeKeys.SET_SEARCH_FILTER_STATE:
			return { ...action.payload };
		case SearchFilterTypeKeys.UPDATE_SEARCH_FILTER:
			return {
				...state,
				...action.data,
			};
		default:
			return state;
	}
};
