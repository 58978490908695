import React from 'react';

import Popover from 'antd/lib/popover';
import loadable from '@loadable/component';

import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

const params = {
	fallback: <Loader />,
};

const EmojiPicker = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "EmojiPicker" */ '@common/react/components/UI/EmojiPicker/EmojiPicker')), params);

interface ChatReactionButtonProps {
	isOpen: boolean;
	handleReactionOpenChange: (newOpen: boolean) => void;
	handleReactionSelect: (emoji) => void;
	getPopupContainer?: (node) => HTMLElement;
}

const ChatReactionButton: React.FC<ChatReactionButtonProps> = ({
	getPopupContainer, isOpen, handleReactionOpenChange, handleReactionSelect,
}) => {
	return (
		<Popover
			placement="right"
			trigger="click"
			open={isOpen}
			getPopupContainer={getPopupContainer}
			onOpenChange={handleReactionOpenChange}
			overlayClassName="popover-chat-message-reaction"
			content={<>
				<EmojiPicker
					emojiSize={24}
					showPreview
					showSkinTones={false}
					native={false}
					set="apple"
					emoji="point_up"
					title="Pick your emoji…"
					emojisToShowFilter={(emoji: any) => !emoji.unified.includes('-') && emoji.added_in === '2.0'}
					onSelect={handleReactionSelect}
				/>
			</>}
		>
			<button type="button" className="btn-chat-message-reaction">
				<i className="fa fa-smile-o" />
			</button>
		</Popover>
	);
};

export default ChatReactionButton;
