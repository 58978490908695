import * as React from 'react';
import {
	NavLink, LinkProps, withRouter, RouteComponentProps,
} from 'react-router-dom';

import * as H from 'history';

type Props = LinkProps & RouteComponentProps<object>;

const LinkWithPrevLocation: React.FC<Props> = ({
	to, children, location, match, staticContext, history, ...rest
}) => {
	let descriptor : H.LocationDescriptor;
	const state = { prevPath: `${location.pathname}${location.search}${location.hash}` };

	if (typeof to === 'string') {
		descriptor = {
			pathname: to,
			state,
		};
	} else {
		descriptor = {
			...to,
			state,
		};
	}

	return <NavLink to={descriptor} {...rest}>{children}</NavLink>;
};

export default withRouter(LinkWithPrevLocation);
