import React from 'react';

export type DraggablePlace = 'center' | 'leftBottom';

interface DraggableProps {
	isDraggable?: boolean;
	isInOrigin?: boolean;
	className?: string;
	style?: React.CSSProperties;
	ignoreElements?: Array<string>;
	padding?: number;
	defaultPosition?: DraggablePlace;
}

interface DraggableData {
	isDragging: boolean;
	shiftX?: number;
	shiftY?: number;
	posX?: number;
	posY?: number;
}

const Draggable: React.FC<DraggableProps> = ({
	isDraggable, isInOrigin, className, style, children, ignoreElements, padding = 5, ...rest
}) => {
	const { defaultPosition = 'center' } = rest;
	const [position, setPosition] = React.useState<{x: string, y: string}>({ x: '50%', y: '50%' });
	const [defaultStyle, setDefaultStyle] = React.useState<React.CSSProperties>(() => {
		if (defaultPosition === 'center') {
			return {};
		}
		return {
			left: defaultPosition === 'leftBottom' ? 'unset' : undefined,
			right: defaultPosition === 'leftBottom' ? `${padding}px` : undefined,
			top: defaultPosition === 'leftBottom' ? 'unset' : undefined,
			bottom: defaultPosition === 'leftBottom' ? `${padding}px` : undefined,
			transform: undefined,
		};
	});

	const draggableRef = React.useRef<HTMLDivElement>(null);
	const draggableData = React.useRef<DraggableData>({ isDragging: false });

	const handleDragStart: React.PointerEventHandler = (e) => {
		if (!draggableRef.current || !isDraggable) return;
		const el = (e.target as any);

		const ignore = ignoreElements?.some((item) => {
			return el.classList.contains(item) || !!el.closest(`.${item}`);
		});

		if (ignore) return;

		draggableData.current.isDragging = true;
		e.currentTarget?.setPointerCapture(e.pointerId);
		const {
			width, height, left, top,
		} = e.currentTarget.getBoundingClientRect();

		draggableData.current.shiftX = e.clientX - left - width / 2;
		draggableData.current.shiftY = e.clientY - top - height / 2;
	};

	const handleDrag: React.PointerEventHandler = (e) => {
		if (!draggableRef.current || !draggableData.current.isDragging || !isDraggable) return;
		e.preventDefault();

		let posX = e.clientX - (draggableData.current.shiftX || 0);
		let posY = e.clientY - (draggableData.current.shiftY || 0);
		const width = draggableRef.current.offsetWidth;
		const height = draggableRef.current.offsetHeight;

		if (posY < padding + height / 2) {
			posY = padding + height / 2;
		}

		if (posX < padding + width / 2) {
			posX = padding + width / 2;
		}

		if (posX + width / 2 + padding > window.innerWidth
			&& window.innerWidth > width + 2 * padding) {
			posX = window.innerWidth - padding - width / 2;
		}

		if (posX + width / 2 + padding > window.innerWidth
			&& window.innerWidth > width + 2 * padding) {
			posX = window.innerWidth - padding - width / 2;
		}

		if (posY + height / 2 + padding > window.innerHeight
			&& window.innerHeight > height + 2 * padding) {
			posY = window.innerHeight - padding - height / 2;
		}

		draggableRef.current.style.right = 'unset';
		draggableRef.current.style.bottom = 'unset';
		draggableRef.current.style.transform = 'translate(-50%, -50%)';
		draggableData.current.posX = posX;
		draggableRef.current.style.left = `${posX}px`;

		draggableData.current.posY = posY;
		draggableRef.current.style.top = `${posY}px`;
		draggableRef.current.style.borderColor = 'green';
	};

	const handleDragEnd: React.PointerEventHandler = (e) => {
		if (!draggableRef.current || !draggableData.current.isDragging) return;
		e.preventDefault();

		draggableData.current.isDragging = false;
		draggableRef.current.style.borderColor = 'transparent';

		if (typeof draggableData.current.posX === 'number') {
			setPosition({
				x: `${draggableData.current.posX}px`,
				y: `${draggableData.current.posY}px`,
			});
			setDefaultStyle({});
		}
	};

	return (
		<div
			ref={draggableRef}
			className={className}
			style={{
				...style,
				position: 'fixed',
				left: !isInOrigin ? position.x : '50%',
				top: !isInOrigin ? position.y : '50%',
				border: '2px solid transparent',
				transform: 'translate(-50%, -50%)',
				...defaultStyle,
			}}
			onPointerDown={handleDragStart}
			onPointerUp={handleDragEnd}
			onPointerMove={handleDrag}
			onPointerLeave={handleDragEnd}
		>
			{children}
		</div>
	);
};

export default Draggable;
