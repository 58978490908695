import React from 'react';
import { useCart } from 'react-use-cart';

import Popover from 'antd/lib/popover';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { getPopupContainer } from '@common/react/components/Utils/Utils';

import ShoppingCartInformation from '@app/components/UI/ShoppingCartInformation/ShoppingCartInformation';

interface Props {
	withoutPopover?: boolean;
}

const ShoppingCartDropdown: React.FC<Props> = ({ withoutPopover }) => {
	const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
	const { totalItems } = useCart();

	React.useEffect(() => {
		setIsInitialized(true);
	}, []);

	const totalCount = isInitialized ? totalItems : 0;

	const content = (<LinkWithPrevLocation to="/shopping-cart">
		<div className="shopping-cart__icon">
			<i className="fa fa-shopping-cart" title="Shopping Cart" />
			<div className="total-items pulse-animation" hidden={totalCount === 0}>
				{totalCount}
			</div>
		</div>
	</LinkWithPrevLocation>);

	if (withoutPopover) {
		return <div className="shopping-cart">{content}</div>;
	}

	return <div className="shopping-cart">
		<Popover
			trigger="click"
			placement="bottomRight"
			getTooltipContainer={getPopupContainer}
			overlayClassName="shopping-cart__popover"
			content={
				<ShoppingCartInformation
					checkout={
						<LinkWithPrevLocation to="/ordering">
							Check out
						</LinkWithPrevLocation>
					}
				/>
			}
		>
			{content}
		</Popover>
	</div>;
};

export default ShoppingCartDropdown;
