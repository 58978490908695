import React from 'react';

import { notification } from 'antd';
import { NotificationInstance, NotificationPlacement } from 'antd/lib/notification/interface';

import '@common/react/scss/components/notificationGlider.scss';

interface NotificationGliderContext {
	api: NotificationInstance;
	placement: NotificationPlacement;
}

const NotificationGliderContext = React.createContext<NotificationGliderContext>({} as NotificationGliderContext);

export const useNotificationGliderContext: () => NotificationGliderContext = () =>
	React.useContext(NotificationGliderContext);

const NotificationGliderProvider: React.FC = ({ children }) => {
	const [api, contextHolder] = notification.useNotification();

	return (
		<NotificationGliderContext.Provider value={{ api, placement: 'bottomRight' }}>
			{children}
			{contextHolder}
		</NotificationGliderContext.Provider>
	);
};

export default NotificationGliderProvider;
