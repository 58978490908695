import * as React from 'react';

import ConfigProvider from 'antd/es/config-provider';
import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import '@common/react/yupLocaleSettings';
import { LoadingProvider } from '@common/react/components/Core/LoadingProvider/LoadingProvider';
import VideoChatModal from '@common/react/components/UI/VideoChat/VideoChatModal';
import ErrorBoundaryWithSentry from '@common/react/components/UI/ErrorBoundaryWithSentry/ErrorBoundaryWithSentry';
import SignalRChats from '@common/react/components/Chat/SignalRChats';
import { ChatSettingsProvider } from '@common/react/components/Chat/ChatSettingsProvider';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import { ReactionsPlugin } from '@common/react/components/Chat/Reactions/ReactionsPlugin';
import { ChatPlugins } from '@common/react/components/Chat/Chat';
import VideoChatGlider from '@common/react/components/Chat/VideoChatGlider';
import Application from '@common/react/components/Core/Application/Application';
import NotificationGliderProvider from '@common/react/components/Chat/NotificationGliderProvider';
import ReactionNotificationHandler from '@common/react/components/Chat/ReactionNotificationHandler';

import Avatar from '@commonTuna/react/components/UI/Avatar/Avatar';

import CartProviderWithUpdate from '@app/components/UI/CartProviderWithUpdate/CartProviderWithUpdate';
import HeaderSearch from '@app/components/UI/Header/HeaderSearch';
import PortalLoader from '@app/components/UI/PortalLoader';
import '@app/scss/components/chatMessageNotificationGlider.scss';
import { MenuStateProvider } from '@app/components/UI/Menu/MenuStateProvider';
import UserStateTracker from '@app/components/UI/UserStateTracker/UserStateTracker';
import { theme } from '@app/components/UI/ThemeConfig/ThemeConfig';
import RouteChangeTracker from '@app/components/Routes/RouteChangeTracker';
import PatientLocationsSetter from '@app/components/UI/PatientLocationsSetter/PatientLocationsSetter';
import { customReduxActions } from '@app/store/CustomReduxActions';
import { User } from '@app/objects/User';
import { Init } from '@app/objects/Init';

import '@app/scss/style.scss';
import RouteWithFooter from '@app/components/Routes/RouteWithFooter';

const params = { fallback: <Loader /> };

const NotFound = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "PageNotFound" */
		'@common/react/components/UI/PageNotFound/PageNotFound'
	)), params);

const renderAvatar = (state) => <Avatar
	src={`${state.avatar}`}
	firstName={state.firstName ?? 'N'}
	lastName={state.lastName ?? 'N'}
/>;

const Layout: React.FC = ({ children }) => {
	return (
		<div className="app-container schema-gray-orange">
			<ErrorBoundaryWithSentry>
				<RouteChangeTracker id="G-B67V40VWKF" />
				<LoadingProvider loader={<PortalLoader />}>
					<RequestProvider
						errorComponents={{
							notFound: () => <RouteWithFooter component={NotFound} />,
						}}
					>
						<CartProviderWithUpdate>
							<MenuStateProvider>
								<UserStateTracker>
									<HeaderSearch />
									<ConfigProvider theme={theme}>
										<Application<User, Init>
											initCustomHandler={customReduxActions}
										>
											<PatientLocationsSetter />
											<NotificationGliderProvider>
												<ChatSettingsProvider plugins={{ [ChatPlugins.Reactions]: ReactionsPlugin }}>
													{children}
													<ReactionNotificationHandler />
													<SignalRChats />
												</ChatSettingsProvider>
												<VideoChatGlider renderAvatar={renderAvatar} />
												<VideoChatModal />
											</NotificationGliderProvider>
										</Application>
									</ConfigProvider>
								</UserStateTracker>
							</MenuStateProvider>
						</CartProviderWithUpdate>
					</RequestProvider>
				</LoadingProvider>
			</ErrorBoundaryWithSentry>
		</div>
	);
};

export default Layout;
