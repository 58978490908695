import { Action, Reducer } from 'redux';
import { addTask } from 'domain-task';

import {
	BaseChatMessage,
	Chat, ChatMessage, ChatMessageAccess, ChatUser, EmojiReaction,
} from '@common/react/components/Chat/Chat';
import { BaseUser, BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { List, transformArrayToList } from '@common/typescript/objects/List';
import { BaseApplicationState, BaseAppThunkAction } from '@common/react/store';
import { RequestType } from '@common/react/components/RequestProvider/RequestProvider';

export interface ChatsState {
	chats: List<Chat>;
	currentChat: Chat | null;
	isLoading: boolean;
	isMessagesLoading: boolean;
	isMessagesLazy: boolean;
	messages: ChatMessagesState;
	chatsLoaded: boolean;
	notViewMessages: Array<number>;
	modalMode: boolean;
}

interface ChatMessagesState {
	[id: number]: List<ChatMessage>;
}

export interface ApplicationStateWithChats<TUser extends BaseUser> extends BaseApplicationState<TUser> {
	chats: ChatsState;
}

export enum TypeKeys {
	RECIEVECHATS = 'RECIEVECHATS',
	REQUESTCHATS = 'REQUESTCHATS',
	ADDCHAT = 'ADDCHAT',
	UPDATECHAT = 'UPDATECHAT',
	SELECTCHAT = 'SELECTCHAT',
	DELETECHAT = 'DELETECHAT',
	REQUESTMESSAGES = 'REQUESTMESSAGES',
	RECIEVEMESSAGES = 'RECIEVEMESSAGES',
	RECIEVEMOREMESSAGES = 'RECIEVEMOREMESSAGES',
	ADDMESSAGE = 'ADDMESSAGE',
	UPDATEMESSAGE = 'UPDATEMESSAGE',
	UPDATEMESSAGEVIEWED = 'UPDATEMESSAGEVIEWED',
	SETMESSAGES = 'SETMESSAGES',
	SETCHATS = 'SETCHATS',
	DELETEMESSAGE = 'DELETEMESSAGE',
	ADDREACTION = 'ADDREACTION',
	REMOVEREACTION = 'REMOVEREACTION'
}

interface ReceiveChatsAction {
	storageName: string;
	type: TypeKeys.RECIEVECHATS;
	items: List<Chat>;
}

interface RequestChatsAction {
	storageName: string;
	type: TypeKeys.REQUESTCHATS;
}

interface RequestChatMessagesAction {
	storageName: string;
	type: TypeKeys.REQUESTMESSAGES;
}

interface RecieveChatMessagesAction {
	storageName: string;
	type: TypeKeys.RECIEVEMESSAGES;
	messages: List<ChatMessage>;
	chatId: number;
}

interface RecieveChatMoreMessagesAction {
	storageName: string;
	type: TypeKeys.RECIEVEMOREMESSAGES;
	messages: List<ChatMessage>;
	chatId: number;
}

interface SetChatMessagesAction {
	storageName: string;
	type: TypeKeys.SETMESSAGES;
	messages: List<ChatMessage>;
	chatId: number;
}

interface AddChatAction {
	storageName: string;
	type: TypeKeys.ADDCHAT;
	chat: Chat;
}

interface AddMessageAction {
	storageName: string;
	type: TypeKeys.ADDMESSAGE;
	message: ChatMessage;
	increase: boolean;
}

interface UpdateMessageAction {
	storageName: string;
	type: TypeKeys.UPDATEMESSAGE;
	message: ChatMessage;
}

interface UpdateMessageViewedAction {
	storageName: string;
	type: TypeKeys.UPDATEMESSAGEVIEWED;
	messageViewer: ChatMessageAccess;
	chatId: number;
	messageId: number;
	viewed: boolean;
	addMessageViewer: boolean;
}

interface UpdateChatAction {
	storageName: string;
	type: TypeKeys.UPDATECHAT;
	chat: Chat;
}

interface SelectChatAction {
	storageName: string;
	type: TypeKeys.SELECTCHAT;
	chat: Chat;
}

interface DeleteChatAction {
	storageName: string;
	type: TypeKeys.DELETECHAT;
	chatId: number;
}

interface SetChats {
	storageName: string;
	type: TypeKeys.SETCHATS;
	chats: List<Chat>;
}

interface RemoveMessage {
	storageName: string;
	type: TypeKeys.DELETEMESSAGE;
	message: ChatMessage;
	chatId: number;
}

interface AddReaction {
	storageName: string;
	type: TypeKeys.ADDREACTION;
	chatId: number;
	messageId: number;
	emojiReaction: EmojiReaction;
}

interface RemoveReaction {
	storageName: string;
	type: TypeKeys.REMOVEREACTION;
	chatId: number;
	messageId: number;
	reactionId: number;
}

type KnownPageAction =
	ReceiveChatsAction
	| RequestChatsAction
	| RequestChatMessagesAction
	| RecieveChatMessagesAction
	| AddChatAction
	| AddMessageAction
	| UpdateMessageAction
	| UpdateMessageViewedAction
	| UpdateChatAction
	| SelectChatAction
	| RecieveChatMoreMessagesAction
	| DeleteChatAction
	| SetChatMessagesAction
	| SetChats
	| RemoveMessage
	| AddReaction
	| RemoveReaction;

export interface ChatsActionCreators<TUser extends BaseUserWithAvatar, TApplicationState extends ApplicationStateWithChats<TUser>> {
	loadChats: (request: RequestType, requestName: string, storageName: string, onLoad?: (data: List<Chat>) => void, data?: any) =>
		BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
	loadMessages: (request: RequestType, requestName: string, storageName: string, chatId: number, loadMore: boolean, count?: number) =>
		BaseAppThunkAction<KnownPageAction, TUser, TApplicationState>;
	addChat: (chat: Chat, storageName: string) => void;
	removeChat: (chatId: number, storageName: string) => void;
	updateChat: (chat: Chat, storageName: string) => void;
	addMessage: (request: RequestType, requestName: string, storageName: string, message: ChatMessage, increaseCounter: boolean) => void;
	updateMessage: (message: ChatMessage, storageName: string) => void;
	updateMessageContent: (message: BaseChatMessage, storageName: string) => void;
	updateMessageViewed: (messageViewer: ChatMessageAccess, chatId: number, messageId: number, viewed: boolean, storageName: string,
		addMessageViewer?: boolean) => void;
	selectChat: (chat: Chat | null, storageName: string) => void;
	changeChatCounter: (chatId: number, value: number, storageName: string) => void;
	updateChatCounter: (chatId: number, unviewedMessagesCount: number, storageName: string) => void;
	addUserToChat: (chatUser: ChatUser, storageName: string) => void;
	removeUserFromChat: (chatUser: ChatUser, storageName: string) => void;
	setMessages: (messages: List<ChatMessage>, chatId: number, storageName: string) => void;
	setChats: (chats: List<Chat>, storageName: string) => void;
	removeMessage: (request: RequestType, requestName: string, storageName: string, messages: List<ChatMessage>, chatId: number) => void;
	addReaction: (chatId: number, messageId: number, emojiReaction: EmojiReaction, storageName: string) => void;
	removeReaction: (chatId: number, messageId: number, reactionId: number, storageName: string) => void;
}

const findChat = (chats: Array<Chat>, chatId: number): Chat | undefined => chats.find((item: Chat) => item.id === chatId);

export function getActionCreators<TUser extends BaseUserWithAvatar, TApplicationState extends ApplicationStateWithChats<TUser>>() {
	return {
		loadChats: (
			request: RequestType,
			requestName: string,
			storageName: string,
			onLoad?: (data: List<Chat>) => void,
			data?: any,
		): BaseAppThunkAction<KnownPageAction, TUser, TApplicationState> =>
			(dispatch, getState) => {
				const state = getState()[storageName];

				if (!state.chatsLoaded) {
					const fetchTask = request<List<Chat>>(requestName, { ...data }).then((data) => {
						const res = {
							...data,
							items: data.list.map((item) => ({
								...item,
								unviewedMessagesCount: item.unviewedMessagesCount > 0 ? item.unviewedMessagesCount : 0,
							})),
						};
						dispatch({ type: TypeKeys.RECIEVECHATS, items: res, storageName });
						onLoad && onLoad(res as List<Chat>);
					});

					dispatch({ type: TypeKeys.REQUESTCHATS, storageName });

					addTask(fetchTask);

					return fetchTask;
				}

				return Promise.resolve(null);
			},
		setChats: (chats: List<Chat>, storageName: string) => (dispatch, getState) => {
			dispatch({ type: TypeKeys.SETCHATS, chats, storageName });
		},
		setMessages: (messages: List<ChatMessage>, chatId: number, storageName: string) => (dispatch, getState) => {
			dispatch({
				type: TypeKeys.SETMESSAGES, messages, chatId, storageName,
			});
		},
		loadMessages: (
			request: RequestType,
			requestName: string,
			storageName: string,
			chatId: number,
			loadMore: boolean,
			count = 20,
		): BaseAppThunkAction<KnownPageAction, TUser, TApplicationState> =>
			(dispatch, getState) => {
				const state = getState();
				const messages = state[storageName].messages[chatId];

				if (!messages || loadMore) {
					const fetchTask = request(requestName, {
						chatId,
						count,
						offset: loadMore ? messages.offset + count : 0,
					}).then((data) => {
						const list = data as List<ChatMessage>;

						list.list.reverse();

						if (loadMore) {
							dispatch({
								type: TypeKeys.RECIEVEMOREMESSAGES, messages: list, chatId, storageName,
							});
						} else {
							dispatch({
								type: TypeKeys.RECIEVEMESSAGES, messages: list, chatId, storageName,
							});
						}
					});

					dispatch({ type: TypeKeys.REQUESTMESSAGES, storageName });

					addTask(fetchTask);

					return fetchTask;
				}
			},
		addChat: (chat: Chat, storageName: string) => (dispatch, getState) => {
			dispatch({ type: TypeKeys.ADDCHAT, chat, storageName });
		},
		changeChatCounter: (chatId: number, value: number, storageName: string) => (dispatch, getState) => {
			const state = getState();
			const chat = findChat(state[storageName].chats.list, chatId);

			if (chat) {
				dispatch({ type: TypeKeys.UPDATECHAT, storageName, chat: { ...chat, unviewedMessagesCount: chat.unviewedMessagesCount + value } });
			}
		},
		updateChatCounter: (chatId: number, unviewedMessagesCount: number, storageName: string) => (dispatch, getState) => {
			const state = getState();
			const chat = findChat(state[storageName].chats.list, chatId);

			if (chat) {
				dispatch({
					type: TypeKeys.UPDATECHAT,
					storageName,
					chat: { ...chat, unviewedMessagesCount: unviewedMessagesCount > 0 ? unviewedMessagesCount : 0 },
				});
			}
		},
		updateChat: (chat: Chat, storageName) => (dispatch, getState) => {
			dispatch({ type: TypeKeys.UPDATECHAT, chat, storageName });
		},
		addMessage: (
			request: RequestType,
			requestName: string,
			storageName: string,
			message: ChatMessage,
			increaseCounter: boolean,
		) => (dispatch, getState) => {
			const state = getState();

			if (state[storageName].messages[message.chatId]) {
				dispatch({
					type: TypeKeys.ADDMESSAGE, message, increase: increaseCounter, storageName,
				});
			} else {
				const fetchTask = request<Chat>(requestName, {
					id: message.chatId,
				}).then((res) => {
					if (res) {
						dispatch({ type: TypeKeys.ADDCHAT, chat: res, storageName });

						res.messages.list.reverse().splice(res.messages.list.length - 1, 1);
						dispatch({
							type: TypeKeys.RECIEVEMESSAGES, messages: res.messages, chatId: res.id, storageName,
						});

						dispatch({
							type: TypeKeys.ADDMESSAGE, message, increase: increaseCounter, storageName,
						});
					}
				});

				addTask(fetchTask);

				return fetchTask;
			}
		},
		updateMessageContent: (message: BaseChatMessage, storageName: string) => (dispatch, getState) => {
			const state: TApplicationState = getState();
			const messages = state[storageName]?.messages?.[message.chatId];

			const updatedMessage = messages?.list?.find((q) => q.id === message.id);

			if (updatedMessage === undefined) return;

			const lastMessage = messages.list[messages.list.length - 1] || null;

			const content = {
				text: message.text,
				files: typeof (message.files) !== 'undefined' && message.files !== null
					? message.files
					: updatedMessage.files,
				loading: message.loading,
			};

			dispatch({ type: TypeKeys.UPDATEMESSAGE, message: { ...updatedMessage, ...content }, storageName });
			if (lastMessage?.id === message?.id) {
				const chat = findChat(state[storageName].chats.list, message.chatId);

				dispatch({ type: TypeKeys.UPDATECHAT, chat: { ...chat, lastMessage: { ...updatedMessage, ...content } }, storageName });
			}
		},
		updateMessage: (message: ChatMessage, storageName: string) => (dispatch, getState) => {
			const state: TApplicationState = getState();
			const messages = state[storageName]?.messages?.[message.chatId];
			const lastMessage = messages.list[messages.list.length - 1] || null;

			dispatch({ type: TypeKeys.UPDATEMESSAGE, message, storageName });
			if (lastMessage?.id === message?.id) {
				const chat = findChat(state[storageName].chats.list, message.chatId);

				dispatch({ type: TypeKeys.UPDATECHAT, chat: { ...chat, lastMessage: message }, storageName });
			}
		},
		updateMessageViewed: (
			messageViewer: ChatMessageAccess,
			chatId: number,
			messageId: number,
			viewed: boolean,
			storageName: string,
			addMessageViewer: boolean = false,
		) =>
			(dispatch, getState) => {
				dispatch({
					type: TypeKeys.UPDATEMESSAGEVIEWED, messageViewer, chatId, messageId, viewed, storageName, addMessageViewer,
				});
			},
		selectChat: (chat: Chat | null, storageName: string) => (dispatch, getState) => {
			dispatch({ type: TypeKeys.SELECTCHAT, chat, storageName });
		},
		removeChat: (chatId: number, storageName: string) => (dispatch, getState) => {
			dispatch({ type: TypeKeys.DELETECHAT, chatId, storageName });
		},
		addUserToChat: (chatUser: ChatUser, storageName: string) => (dispatch, getState) => {
			const state: TApplicationState = getState();
			const chat = findChat(state[storageName].chats.list, chatUser.chatId);

			if (chat) {
				if (chat.contactsIds.indexOf(chatUser.userId) === -1) {
					const newChatInfo = {
						contacts: [...chat.contacts, chatUser.user],
						contactsIds: [chat.contactsIds ? { ...chat.contactsIds } : [], chatUser.userId],
					};

					dispatch({ type: TypeKeys.UPDATECHAT, chat: { ...chat, ...newChatInfo }, storageName });
				}
			}
			// } else if (chatUser.chat) {
			//  dispatch({type: TypeKeys.ADDCHAT, chat: chatUser.chat});
			// }
		},
		removeUserFromChat: (chatUser: ChatUser, storageName: string) => (dispatch, getState) => {
			const state: TApplicationState = getState();
			const chat = findChat(state[storageName].chats.list, chatUser.chatId);

			if (chat) {
				const newChatInfo = {
					contacts: chat.contacts.filter((contact: BaseUserWithAvatar) => contact.id !== chatUser.userId),
					contactsIds: chat.contactsIds.filter((id: number) => id !== chatUser.userId),
				};

				dispatch({ type: TypeKeys.UPDATECHAT, chat: { ...chat, ...newChatInfo }, storageName });
			}
		},
		removeMessage: (
			request: RequestType,
			requestName: string,
			storageName: string,
			message: ChatMessage,
			chatId: number,
		) => (dispatch, getState) => {
			const state = getState();
			const messages = state[storageName]?.messages?.[message.chatId];

			if (messages && !((messages?.count === undefined || messages?.count > 20) && messages?.list.length <= 20)) {
				dispatch({
					type: TypeKeys.DELETEMESSAGE, chatId, message, storageName,
				});
			} else {
				const fetchTask = request<Chat>(requestName, {
					id: message.chatId,
				}).then((res) => {
					if (res) {
						res.messages.list.reverse();
						const lastMessage = res.messages.list[res.messages.list.length - 1] || null;
						dispatch({ type: TypeKeys.UPDATECHAT, chat: { ...res, lastMessage }, storageName });

						dispatch({
							type: TypeKeys.RECIEVEMESSAGES, messages: res.messages, chatId: res.id, storageName,
						});
					}
				});

				addTask(fetchTask);

				return fetchTask;
			}
		},
		addReaction: (chatId: number, messageId: number, emojiReaction: EmojiReaction, storageName: string) => (dispatch, getState) => {
			dispatch({
				type: TypeKeys.ADDREACTION, chatId, messageId, emojiReaction, storageName,
			});
		},
		removeReaction: (chatId: number, messageId: number, reactionId: number, storageName: string) => (dispatch, getState) => {
			dispatch({
				type: TypeKeys.REMOVEREACTION, chatId, messageId, reactionId, storageName,
			});
		},
	};
}

export function getReducer<TUser extends BaseUserWithAvatar>(storageName: string = 'chats'): Reducer<ChatsState> {
	return (state: ChatsState = {
		isLoading: false,
		isMessagesLoading: false,
		isMessagesLazy: false,
		chats: transformArrayToList([]),
		messages: {},
		currentChat: null,
		chatsLoaded: false,
		notViewMessages: [],
		modalMode: false,
	}, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction;

		if (!(action.type in TypeKeys)) {
			return state || {
				isLoading: false,
				chats: transformArrayToList([]),
				messages: {},
			};
		}

		if (!action.storageName || action.storageName !== storageName) {
			return state;
		}

		let messages;

		switch (action.type) {
			case TypeKeys.REQUESTCHATS:
				return { ...state, isLoading: true };
			case TypeKeys.REQUESTMESSAGES:
				return { ...state, isMessagesLoading: true };
			case TypeKeys.ADDCHAT:
				if (findChat(state.chats.list, action.chat.id)) {
					return state;
				}
				const newChats = { ...state.chats, count: state.chats.count, list: [action.chat, ...state.chats.list] };
				return { ...state, chats: newChats };
			case TypeKeys.UPDATECHAT:
				return {
					...state,
					chats: {
						...state.chats,
						list: state.chats.list.map((chat: Chat) => (chat.id === action.chat.id ? { ...chat, ...action.chat } : chat)),
					},
					currentChat: state.currentChat && state.currentChat.id === action.chat.id
						? { ...state.currentChat, ...action.chat } : state.currentChat,
				};
			case TypeKeys.SELECTCHAT:
				return { ...state, currentChat: action.chat };
			case TypeKeys.DELETECHAT:
				return {
					...state,
					chats: {
						...state.chats,
						count: state.chats.count - 1,
						list: state.chats.list.filter((chat: Chat) => chat.id !== action.chatId),
					},
				};
			case TypeKeys.RECIEVECHATS:
				return {
					...state, isLoading: false, chats: action.items, chatsLoaded: true,
				};
			case TypeKeys.SETCHATS:
				return {
					...state, isLoading: false, chats: action.chats, chatsLoaded: true,
				};
			case TypeKeys.RECIEVEMESSAGES:
				return {
					...state,
					isMessagesLoading: false,
					messages: {
						...state.messages,
						[action.chatId]: action.messages,
					},
				};
			case TypeKeys.RECIEVEMOREMESSAGES:
				return {
					...state,
					isMessagesLoading: false,
					messages: {
						...state.messages,
						[action.chatId]: {
							...state.messages[action.chatId],
							list: [...action.messages.list, ...state.messages[action.chatId].list],
							offset: action.messages.offset,
						},
					},
				};
			case TypeKeys.SETMESSAGES:
				return {
					...state,
					isMessagesLoading: false,
					messages: {
						...state.messages,
						[action.chatId]: {
							...state.messages[action.chatId],
							list: [...action.messages.list],
							offset: action.messages.offset,
						},
					},
				};
			case TypeKeys.ADDMESSAGE:
				messages = state.messages[action.message.chatId];

				const newList = [...messages.list, action.message];

				const chatIndex = state.chats.list.findIndex((item: Chat) => item.id === action.message.chatId);

				const chat = state.chats.list[chatIndex];

				return {
					...state,
					chats: {
						...state.chats,
						list: [
							{
								...chat,
								lastMessage: action.message,
								unviewedMessagesCount: chat.unviewedMessagesCount + (action.increase ? 1 : 0),
							},
							...state.chats.list.slice(0, chatIndex),
							...state.chats.list.slice(chatIndex + 1),
						],
					},
					notViewMessages: action.increase ? state.notViewMessages.concat(action.message.id) : state.notViewMessages,
					messages: {
						...state.messages,
						[action.message.chatId]: { ...messages, list: newList, count: state.messages[action.message.chatId].count + 1 },
					},
				};
			case TypeKeys.UPDATEMESSAGE:
				messages = state.messages[action.message.chatId];

				return {
					...state,
					isMessagesLoading: false,
					messages: {
						...state.messages,
						[action.message.chatId]: {
							...messages,
							list: messages.list.map((item: ChatMessage) => {
								if (action.message.id === item.id) {
									return action.message;
								}

								return item;
							}),
						},
					},
				};
			case TypeKeys.UPDATEMESSAGEVIEWED:
				messages = state.messages[action.chatId];
				const notViewMessages = action.viewed ? state.notViewMessages.filter((messageId) => messageId !== action.messageId)
					: state.notViewMessages;
				return {
					...state,
					isMessagesLoading: false,
					notViewMessages,
					messages: {
						...state.messages,
						[action.chatId]: {
							...messages,
							list: messages.list.map((item: ChatMessage) => {
								if (item.id === action.messageId) {
									return {
										...item,
										messageViewers: action.addMessageViewer
											? item.messageViewers
												? [...item.messageViewers, action.messageViewer]
												: [action.messageViewer]
											: item.messageViewers,
										viewed: action.viewed,
									};
								}

								return item;
							}),
						},
					},
				};
			case TypeKeys.DELETEMESSAGE:
				messages = state.messages[action.chatId];

				const deleteMessage = messages.list.find((item) => item.id === action.message.id);

				if (!deleteMessage) {
					return state;
				}

				const currentChatIndex = state.chats.list.findIndex((item: Chat) => item.id === action.message.chatId);
				const currentChat = state.chats.list[currentChatIndex];
				const newLastMessage = currentChat.lastMessage?.id === deleteMessage?.id
					? messages.list?.[messages.list.length - 2] || null : currentChat.lastMessage;

				return {
					...state,
					chats: {
						...state.chats,
						list: [
							{
								...currentChat,
								lastMessage: newLastMessage,
								unviewedMessagesCount: currentChat.unviewedMessagesCount + (action.message.viewed ? 0 : -1),
							},
							...state.chats.list.slice(0, currentChatIndex),
							...state.chats.list.slice(currentChatIndex + 1),
						],
					},
					isMessagesLoading: false,
					messages: {
						...state.messages,
						[action.chatId]: {
							...messages,
							count: messages.count - 1,
							offset: messages.offset - 1,
							list: messages.list.filter((item: ChatMessage) => item.id !== action.message.id),
						},
					},
				};
			case TypeKeys.ADDREACTION: {
				messages = state.messages[action.chatId];
				const newEmojiReaction = { ...action.emojiReaction, animate: action.emojiReaction?.animate ?? true };
				return {
					...state,
					messages: {
						...state.messages,
						[action.chatId]: {
							...messages,
							list: messages.list.map((message) => (message.id !== action.messageId
								? message
								: {
									...message,
									emojiReactions: message.emojiReactions === null
										? [newEmojiReaction]
										: message.emojiReactions.map((emojiReaction) => emojiReaction.id).indexOf(action.emojiReaction.id) < 0
											? [...message.emojiReactions, newEmojiReaction]
											: message.emojiReactions.map((emojiReaction) => (emojiReaction.id !== action.emojiReaction.id
												? emojiReaction
												: newEmojiReaction)),
								})),
						},
					},
				};
			}
			case TypeKeys.REMOVEREACTION:
				messages = state.messages[action.chatId];
				return {
					...state,
					messages: {
						...state.messages,
						[action.chatId]: {
							...messages,
							list: messages.list.map((message) => (message.id !== action.messageId
								? message
								: {
									...message,
									emojiReactions: message.emojiReactions.filter((emojiReaction) => emojiReaction.id !== action.reactionId),
								})),
						},
					},
				};
			default:
				return state;
		}
	};
}
