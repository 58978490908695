import React from 'react';

import Tabs, { TabsProps } from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import GoogleMapReact from 'google-map-react';
import Tag from 'antd/lib/tag';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import { LocationPortal } from '@app/objects/CompanyPortal';

import '@app/scss/components/locations.scss';
import { Location } from '@app/objects/Location';
import WithPathLink from '@app/components/UI/WithPathLink/WithPathLink';
import { getLocationName } from '@app/components/Utils';
import { OverallRating } from '@app/components/Pages/DoctorPortal/Reviews';
import Stars from '@app/components/UI/Stars/Stars';

interface IPros {
	locations: Array<LocationPortal>;
	containerClassName?: string;
	content?: (location: LocationPortal) => JSX.Element;
	reviews?: (location: LocationPortal) => React.ReactNode;
	hideTitle?: boolean;
	hideLink?: boolean;
}

interface MapProps {
	address: string;
	addressList?: Array<string>;
}

export const getAddress = (item) => {
	return [item.addressEn, item.city, item.state, item.zip].filter((e) => e).join(', ');
};

function codeAddress(map, maps, address: string, addressList?: Array<string>, onLoad?: (address, result) => void) {
	const geocoder = new maps.Geocoder();
	const gedData = (currentAddress) => {
		geocoder.geocode({ address: currentAddress }, (results, status) => {
			if (status === 'OK') {
				if (address === currentAddress) {
					map.setCenter(results[0].geometry.location);
				}
				// eslint-disable-next-line
				new maps.Marker({
					map,
					position: results[0].geometry.location,
				});
				onLoad && onLoad(currentAddress, results[0].geometry.location);
			} else {
				console.log(`Geocode was not successful for the following reason: ${status}`);
			}
		});
	};
	const list = addressList || [];
	(list.includes(address) ? list : address ? [address].concat(list) : list)
		.forEach(gedData);
}

export const SimpleMap: React.FC<MapProps> = ({ address, addressList }) => {
	const [maps, setMaps] = React.useState<any>();
	const [map, setMap] = React.useState<any>();
	const addressRef = React.useRef<any>({});
	const defaultProps = {
		center: { lat: -34.397, lng: 150.644 },
		zoom: 12,
	};

	React.useEffect(() => {
		if (map && maps) {
			if (addressRef.current[address]) {
				map.setCenter(addressRef.current[address]);
			} else {
				codeAddress(map, maps, address);
			}
		}
	}, [address]);

	const renderMarker = (map, maps) => {
		setMap(map);
		setMaps(maps);
		return codeAddress(map, maps, address, addressList, (address, result) => {
			addressRef.current[address] = result;
		});
	};

	return (
		<div className="location-map">
			<GoogleMapReact
				bootstrapURLKeys={{ key: 'AIzaSyB_Vis358nPu31ZjN8OUdxbMwr9H9JgSso' }}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
			/>
		</div>
	);
};

interface ILocationModal {
	location: Location;
	buttonClassName?: string;
	buttonValue?: JSX.Element;
}

export const LocationModal: React.FC<ILocationModal> = ({ location, buttonValue, buttonClassName }) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	const close = () => setVisible(false);

	const open = () => setVisible(true);
	const { addressEn, zip, city } = location;

	return (
		<>
			<button type="button" onClick={open} className={`btn ${buttonClassName || ''}`}>
				{buttonValue || location.nameEn}
			</button>
			<Modal
				open={visible}
				onCancel={close}
				title={<>
					<WithPathLink prefix="clinic" path={location.portalPathEn}>
						{location.nameEn}
					</WithPathLink>
				</>}
				width={600}
				className="location-popap"
				footer={null}
			>
				<div>
					<strong>Address:</strong>
					{' '}
					{addressEn}
					,
					{' '}
					{city}
					,
					{' '}
					{zip}
				</div>
				<div style={{ marginBottom: '10px' }}>
					<strong>Phone:</strong>
					{' '}
					{location.phone}
				</div>
				<SimpleMap address={`${addressEn}${city ? `, ${city}` : ''}${zip ? `, ${zip}` : ''}`} />
			</Modal>
		</>
	);
};

const showTime = (time) => {
	const [h, m] = time.split(':');
	return `${+h > 12 ? `${+h - 12}` : h}:${m} ${+h > 12 ? 'p' : 'a'}.m.`;
};

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const LocationNode: React.FC<{ content, reviews, item, hideLink?}> = ({
	item, reviews, content, hideLink,
}) => {
	const arr = React.useMemo(() =>
		[item.addressEn, item.city, item.state, item.zip].filter((str) => str), []);
	const address = arr.filter((e) => e).join(', ');

	const workingHours = React.useMemo(() => {
		if (!item.workingHours) return [];
		const whs = item.workingHours?.slice()
			?.sort((a, b) => {
				if (a.dayOfWeek === 0) return 1;
				if (b.dayOfWeek === 0) return -1;
				return a.dayOfWeek - b.dayOfWeek;
			});
		const obj: any = {};
		whs.forEach((wh) => {
			const key = `${wh.startTime}-${wh.endTime}`;
			if (!obj[key]) {
				obj[key] = [{ ...wh }];
			} else {
				obj[key] = obj[key].concat(wh);
			}
		});
		return Object.values(obj)
			.map((arr: any, i) => {
				let title = days[arr[0].dayOfWeek];
				for (let i = 1; i < arr.length; i++) {
					const isEnd = arr[i].dayOfWeek === 6 && arr[i + 1]?.dayOfWeek === 0;
					if (arr[i].dayOfWeek - 1 !== arr[i - 1]?.dayOfWeek && !isEnd && !(arr[i].dayOfWeek === 0 && arr[i - 1]?.dayOfWeek === 6)) {
						title = `${title}, ${days[arr[i].dayOfWeek]}`;
					} else if (i + 1 === arr.length
						|| (arr[i].dayOfWeek + 1 !== arr[i + 1]?.dayOfWeek && !isEnd)) {
						title = `${title} - ${days[arr[i].dayOfWeek]}`;
					}
				}
				return {
					id: i,
					title,
					startTime: arr[0].startTime,
					endTime: arr[0].endTime,
				};
			});
	}, [item.workingHours]);

	return <div className="row card-panel location">
		<div className="clearfix">
			{address ? <>
				<strong>
					Address:&nbsp;
				</strong>
				{address}
			</> : null}
			{workingHours.length ? <>
				<br />
				<strong>
					Working Hours:&nbsp;
				</strong>
				{workingHours.map((wh) => <React.Fragment
					key={wh.id}
				>
					<Tag color="orange">
						<strong>
							{wh.title}
							:
						</strong>
						{' '}
						{showTime(wh.startTime)}
						{' '}
						-
						{' '}
						{showTime(wh.endTime)}
					</Tag>
				</React.Fragment>)}
			</> : null}
			{hideLink ? null : <div className="pull-right">
				<LinkWithPrevLocation
					className="pull-right"
					to={{
						pathname: `/clinic/${item.portalPathEn}`,
					}}
				>
					More About
					{' '}
					{item.nameEn}
					{' '}
					Clinic
				</LinkWithPrevLocation>
			</div>}
		</div>
		<div className="location-inner">
			<SimpleMap address={item.addressEn} />
			<div className="info-block">
				{content && content(item)}
			</div>
		</div>
		{reviews && <div className="location-reviews">
			{reviews && reviews(item)}
		</div>
		}
	</div>;
};

const Locations: React.FC<IPros> = ({
	locations, containerClassName, content, reviews, hideTitle, hideLink,
}) => {
	if (locations.length === 1) {
		const item = locations[0];
		return (
			<div id="locations" className={`mb20 locations scroll-top ${containerClassName || ''}`}>
				<div className="location-tab">
					{hideTitle ? null : <h4>
						<div className="stars">
							<Stars value={item.averageReviewRate || 0} />
						</div>
						{getLocationName(item, false)}
					</h4>}
					<LocationNode
						content={content}
						item={item}
						reviews={reviews}
						hideLink={hideLink}
					/>
				</div>
			</div>
		);
	}

	const items: TabsProps['items'] = locations.map((item, index) => {
		return {
			key: index.toString(),
			label: getLocationName(item, false),
			className: 'location-tab',
			children: <LocationNode
				content={content}
				item={item}
				reviews={reviews}
			/>,
		};
	});

	return (
		locations.length > 0 ? <div id="locations" className={`mb20 locations scroll-top ${containerClassName || ''}`}>
			<Tabs type="card" defaultActiveKey="0" items={items} />
		</div> : null
	);
};

export default Locations;
