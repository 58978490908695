/**
 * ## ServerPageProvider.tsx ##
 * This file contains ServerPageProvider component
 * @packageDocumentation
 */

import React from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import once from 'lodash/once';

import { Loading } from '@common/react/components/UI/Loading/Loading';
import {
	ItemProvider,
	useItemProviderContext,
} from '@common/react/components/Core/ItemProvider/ItemProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import PageNotFound from '@common/react/components/UI/PageNotFound/PageNotFound';
import NotFoundComponent from '@common/react/components/Core/NotFoundPageProvider/NotFoundComponent';
import { Lang } from '@common/typescript/objects/Lang';
import { useRequestProviderContext } from '@common/react/components/RequestProvider/RequestProvider';

export interface ServerPageProviderContext {
	state: {
		page: any,
		getFromContext?: boolean;
		error?: string;
	};
}

export const createServerPageProviderContext = once(() => React.createContext({} as ServerPageProviderContext));

export const useServerPageProviderContext: () => ServerPageProviderContext = () =>
	React.useContext(createServerPageProviderContext());

/**
 * This is the description of the interface
 *
 * @interface ServerPageProviderProps
 */
export interface ServerPageProviderProps {
	/**
	 * render content
	 * @param initPage - serverPage data. May not be defined
	 * @return page content
	 */
	inner: (initPage) => React.ReactNode;
	/**
	 * pathname where we want to display the page. Used to avoid using data from another page
	 */
	path?: string;
	/**
	 * a function that determines what state to take from redux
	 * @param state - redux state
	 * @return global page data
	 */
	getState?: (state) => any;
	/**
	 * custom loader. by default <Loader defaultLoader={<Loading />} />
	 */
	loader?: React.ReactNode;
	/**
	 * custom content for not found page. by default <NotFoundComponent defaultNotFoundComponent={<PageNotFound />} />
	 */
	notFoundComponent?: React.ReactNode;
	/**
	 * a function to take the value of an element from the page data
	 * @param value - global page data
	 * @return page date
	 */
	transformToItem?: (value) => any;
	/**
	 * default initPage. Used when we have page data but the data from Redux is empty.
	 */
	page?: any;
	/**
	 * true if we don't have to use a helmet
	 */
	withoutHelmet?: boolean;
	/**
	 * By default used title from page langs
	 */
	customTitle?: string;
	/**
	 * returns which language to use
	 */
	language?: Lang;
	/**
	 * sends what title will be if there is no customTitle and data from langs
	 */
	defaultTitle?: string;
	/**
	 * used if an error occurred while loading data
	 * @param error - error message
	 */
	onRequestError?: (error: string) => void;
	/**
	 * function to separate when you don't need to use data from spp.
	 * By default it ignore serverPage when id from url not define or id < 0
	 * @param error - error message
	 */
	getIgnoreServerPage?: (id, initPage) => boolean;
	/**
	 * determines whether to load data again when changing pathname
	 */
	reloadByPathChange?: boolean;
	/**
	 * additional params for load request
	 */
	additionalParams?: any;
	/**
	 * determines whether the child spp should take values from the context of the current
	 */
	getInnerPageFromContext?: boolean;
	/**
	 * time to live (ms) for cached response at RequestProvider if cache is available
	 */
	ttl?: number;
}

interface ServerPageProviderInnerProps {
	inner: (initPage) => React.ReactNode;
	loader: React.ReactNode;
	notFoundComponent: React.ReactNode;
	pathname: string;
	language: Lang;
	skipInitLoad: boolean;
	withoutHelmet?: boolean;
	customTitle?: string;
	defaultTitle?: string;
	reloadByPathChange?: boolean;
}

const defaultGetState = (state) => state.serverPage;

const ServerPageProviderInner: React.FC<ServerPageProviderInnerProps> = ({
	inner,
	loader,
	notFoundComponent,
	pathname,
	skipInitLoad,
	withoutHelmet,
	customTitle,
	language,
	defaultTitle,
	reloadByPathChange,
}) => {
	const context = useItemProviderContext<any>();

	if (!context.state) throw 'Need ItemsProvider context!';

	const {
		state: {
			item, loading, error, itemLoading,
		}, actions: { load },
	} = context;
	const [skipLoad, setSkipLoad] = React.useState(skipInitLoad);
	const [prevPath, setPrevPath] = React.useState(pathname);

	React.useEffect(() => {
		if ((error || (reloadByPathChange && prevPath !== pathname)) && !skipLoad) {
			load();
			setPrevPath(pathname);
		}
		setSkipLoad(false);
	}, [pathname, reloadByPathChange]);

	const info = React.useMemo(() => item?.langs?.find((lang) => lang.language === language), [item, language]);
	const title = customTitle || info?.title || defaultTitle;

	return (<>
		{!withoutHelmet && <Helmet>
			{title && <title>{title}</title>}
		</Helmet>}
		{(error === 'Page not found')
			? notFoundComponent
			: (loading || !item || itemLoading) && !error
				? loader
				: inner(error ? { ...item, error: item?.error || error } : item)
		}
	</>);
};

const ServerPageProvider: React.FC<ServerPageProviderProps> = (props) => {
	const SppContext = createServerPageProviderContext();
	const sppContext = useServerPageProviderContext();
	const defaultTransformToItem = sppContext?.state ? (value) => value?.innerPage : (value) => value;
	const location = useLocation();
	const { id } = useParams<{id?: string}>();
	const {
		path, getState = defaultGetState, inner, loader: loaderFromProps,
		notFoundComponent: notFoundComponentFromProps, transformToItem = defaultTransformToItem, page, reloadByPathChange,
	} = props;
	const { withoutHelmet, language = Lang.En, customTitle } = props;
	const serverPage = useSelector(getState, shallowEqual);
	const loader = loaderFromProps || <Loader defaultLoader={<Loading />} />;
	const notFoundComponent = notFoundComponentFromProps || <NotFoundComponent defaultNotFoundComponent={<PageNotFound />} />;
	const requestContext = useRequestProviderContext();

	const [initPage, setInitPage] = React.useState<any>(() => {
		const cacheData = requestContext?.actions?.getFromCache(
			'pageLoader',
			{ path: `${location.pathname}${location.search}`, ...props.additionalParams, id: 0 },
		);
		if (cacheData) return cacheData;

		const value = page
			|| (sppContext?.state?.page && (serverPage?.page || sppContext?.state?.getFromContext)
				? sppContext?.state?.page
				: (serverPage?.path === path || path === undefined) && serverPage?.page
					? { ...serverPage?.page } : undefined);
		return value ? JSON.parse(JSON.stringify({ ...value })) : value;
	});
	const error = React.useMemo(() =>
		(serverPage.page !== null && (serverPage.page?.id === 0 || !serverPage.path) ? 'Page not found' : ''), [location.pathname]);

	const ignoreServerPage = props.getIgnoreServerPage ? props.getIgnoreServerPage(id, initPage) : id && !isNaN(+id) && +id < 0;

	const item = ignoreServerPage ? {} : transformToItem(initPage);
	const value = { state: { page: item, getFromContext: props.getInnerPageFromContext, error } };

	React.useEffect(() => {
		if (sppContext?.state && sppContext?.state?.page && sppContext?.state?.getFromContext) {
			setInitPage(sppContext?.state?.page);
		}
	}, [sppContext?.state?.page]);

	React.useEffect(() => {
		if (serverPage.page) {
			serverPage.page = null;
		}
		if (!error && !ignoreServerPage && requestContext?.actions?.updateCache && item) {
			requestContext.actions.updateCache(
				'pageLoader',
				{ path: `${location.pathname}${location.search}`, ...props.additionalParams, id: 0 },
				item,
				props.ttl,
			);
		}
	}, []);

	if (sppContext?.state && !sppContext?.state?.page && sppContext?.state?.getFromContext) {
		return <>{loader}</>;
	}

	return (
		<SppContext.Provider value={value}>
			<ItemProvider
				ttl={props.ttl}
				item={error || ignoreServerPage ? {} : item}
				type="pageLoader"
				loadRequest="pageLoader"
				additionalParams={{ path: `${location.pathname}${location.search}`, ...props.additionalParams }}
				id={0}
				error={ignoreServerPage ? undefined : error}
				onRequestError={props.onRequestError}
				skipInitLoad={!!item || !!error}
				onLoad={(response) => !initPage && setInitPage(response)}
			>
				{props.children}
				<ServerPageProviderInner
					reloadByPathChange={reloadByPathChange}
					customTitle={customTitle}
					language={language}
					withoutHelmet={withoutHelmet ?? !!sppContext.state}
					defaultTitle={props.defaultTitle}
					pathname={location.pathname}
					loader={loader}
					notFoundComponent={notFoundComponent}
					inner={inner}
					skipInitLoad={ignoreServerPage || !!item || !!error}
				/>
			</ItemProvider>
		</SppContext.Provider>
	);
};

export default ServerPageProvider;
