import React from 'react';
import ContentLoader from 'react-content-loader';

const SpecialImageLoader = (props) => (
	<ContentLoader
		width="100%"
		height="100%"
		viewBox="0 0 300 265"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="0" y="0" rx="25" ry="25" width="100%" height="100%" />
	</ContentLoader>
);

export default SpecialImageLoader;
