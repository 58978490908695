import * as React from 'react';

import { getMoneyString } from '@commonTuna/react/components/Utils';

import '@app/scss/components/addToCartButton.scss';
import { Special } from '@app/objects/Special';
import { CartItem, transformSpecialToCartItem } from '@app/objects/CartItem';
import useShoppingCart from '@app/hooks/useShoppingCart';

interface Props {
	special: Special | CartItem;
	withTotal?: boolean;
}

const AddToCartButton: React.FC<Props> = (props) => {
	const specialForStore = transformSpecialToCartItem(props.special);
	const { addCartItem, updateCartItemQuantity, getItem } = useShoppingCart();

	const [isInitialized, setIsInitialized] = React.useState<boolean>(false);

	React.useEffect(() => {
		setIsInitialized(true);
	}, [isInitialized]);

	const storedItem: CartItem | undefined = getItem(specialForStore.id);

	const withTotal = props.withTotal ?? true;

	const getQuantityTitle = (quantity: number) => {
		return `${quantity} unit${quantity > 1 ? 's' : ''}`;
	};

	const quantity = storedItem?.quantity;

	return <div className="add-to-cart-btn">
		{isInitialized && storedItem && quantity
			? <div className="quantity-btn">
				<button
					type="button"
					className="quantity-btn__action"
					onClick={() => updateCartItemQuantity(storedItem.id, quantity - 1)}
				>
					&ndash;
				</button>
				<div className="quantity-btn__title">
					<div className="unit">{getQuantityTitle(quantity)}</div>
					{withTotal && <div>{getMoneyString(storedItem.itemTotal || 0)}</div>}
				</div>
				<button
					type="button"
					className="quantity-btn__action"
					onClick={() => updateCartItemQuantity(storedItem.id, quantity + 1)}
				>
					+
				</button>
			</div>
			: <button type="button"className="add-special-btn" onClick={() => addCartItem(specialForStore, 1)}>Add to cart</button>
		}
	</div>;
};

export default AddToCartButton;
