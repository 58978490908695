import { Chat, ChatKind } from '@common/react/components/Chat/Chat';
import { getUserName } from '@common/react/utils/utils';

import { BaseUserWithAvatar } from '@common/react/objects/BaseUser';
import { linkRegex, regularLink } from '@common/react/components/UI/LinkPreview/LinkPreview';

export const getChatName = (chat: Chat, userId: number) => {
	const contacts = chat.contacts.filter((user: BaseUserWithAvatar) => user.id !== userId);

	return chat.kind === ChatKind.Personal
		? getUserName(contacts[0])
		: chat.name
			? chat.name
			: contacts.map((contact: BaseUserWithAvatar, index) => (index > 0 ? ', ' : '') + getUserName(contact));
};

export const transformMentionMessage = (text: string, contacts: Array<BaseUserWithAvatar>, withLink: boolean) => {
	return contacts.reduce<string>((newText, contact) => {
		const name = getUserName(contact);
		const regx = new RegExp(`@${name}`, 'g');

		return newText.replace(
			regx,
			withLink ? `<a class="chat-component__mention" data-id="${contact.id}">${getUserName(contact)}</a>` : getUserName(contact),
		);
	}, text);
};

const linkReplacer = (text: string, ...rest) => {
	return typeof rest?.[7] === 'string' && rest[7].includes('emoji-icon') ? text : regularLink(text);
};

export const transformLinks = (text: string) => {
	const regex = new RegExp(linkRegex);
	return text.replace(regex, linkReplacer);
};
