import React from 'react';

import { request } from '@common/react/components/Api';

interface Props {
	transmuted: boolean;
	render?: (transmuteBack: () => void) => JSX.Element;
	onSuccess?: () => void;
}

const TransmutationBack: React.FC<Props> = ({ transmuted, render, onSuccess }) => {
	const transmuteBack = () => {
		request('transmutation', {
			id: 0,
			transmutation: !transmuted,
		}).then(() => {
			window.location.href = '/';
			onSuccess && onSuccess();
		}).catch((err) => console.log(err));
	};

	const renderComponent = render
		? render(transmuteBack)
		: (<li>
			<i className="fa fa-arrow-left" title="Login back" onClick={transmuteBack} />
		</li>);

	return <>{transmuted && renderComponent}</>;
};

export default TransmutationBack;
