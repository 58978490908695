import React from 'react';
import { useDispatch } from 'react-redux';

import RoomMember from '@common/react/components/UI/VideoChat/RoomMember';
import '@common/react/scss/components/room.scss';
import { TypeKeys } from '@common/react/store/VideoChat';

interface RoomProps {
	token: string;
	roomIdentifier: string;
	isMinimized?: boolean;
	isMute?: boolean;
	isCameraOff?: boolean;
	isFullscreen?: boolean;
	isShareScreen?: boolean;
	setShareScreen: (value: boolean) => void;
	preventCameraStart?: boolean;
	onLoadEnd?: () => void;
	onError?: () => void;
	onFirstConnect?: () => void;
}

const RoomComponent: React.FC<RoomProps> = (props) => {
	const {
		token,
		roomIdentifier,
		isMinimized,
		isMute,
		isCameraOff,
		isFullscreen,
		isShareScreen,
		setShareScreen,
		preventCameraStart,
		onLoadEnd,
		onError,
		onFirstConnect,
	} = props;

	const dispatch = useDispatch();

	const [room, setRoom] = React.useState<any | null>(null);
	const roomRef = React.useRef<any>(room);
	const [members, setMembers] = React.useState<Array<any>>([]);
	const [hasMedia, setHasMedia] = React.useState<boolean>(false);
	const [videoPublication, setPublication] = React.useState<any>(null);

	const displayStream = React.useRef<MediaStream>();
	const twilioVideo = React.useRef<any>();
	const muteRef = React.useRef<boolean>(isMute || false);
	const connected = React.useRef<boolean>(false);

	React.useEffect(() => {
		importTwilioVideo()
			.then((res) => {
				twilioVideo.current = res;
				getMedia(res)
					.catch((e) => {
						console.error(e);
						onError && onError();
					});
			})
			.catch(() => onError && onError());

		return () => {
			const currentRoom = roomRef.current || room;

			if (currentRoom && currentRoom.localParticipant.state === 'connected') {
				currentRoom
					.localParticipant
					.tracks
					.forEach((trackPublication) => {
						if (trackPublication.track.kind !== 'data') {
							trackPublication.track.detach();
							trackPublication.track.stop();
							trackPublication.unpublish();
						}
					});
				displayStream.current?.getTracks()
					.forEach((track) => track.stop());
				currentRoom.disconnect();
				connected.current = false;
			}
		};
	}, [token]);

	const importTwilioVideo = async () => {
		return import('twilio-video');
	};

	const getMedia = async (twilioVideo) => {
		try {
			const stream = isCameraOff
				? await navigator.mediaDevices.getUserMedia({ video: isCameraOff, audio: false }) : undefined;

			stream?.getTracks().forEach((track) => track.stop());
			if ((stream && stream.getVideoTracks().length > 0) || !isCameraOff) {
				setHasMedia(true);
				connectToTwilio(twilioVideo);
			}
		} catch (e) {
			console.log(e);
			onError && onError();
		}
	};

	const handleDisconnect = (member) => {
		setMembers((prevMembers) => {
			const members = prevMembers.filter((m) => m !== member);
			if (members.length <= 0) {
				dispatch({ type: TypeKeys.CLEAR });
			}

			return members;
		});
	};

	const connectToTwilio = (twilioVideo) => {
		const onConnect = (member) => {
			setMembers((prevMembers) => [...prevMembers, member]);
			onFirstConnect && onFirstConnect();
			connected.current = true;
			// member.on('disconnected', onDisconnect);
		};

		twilioVideo.connect(token, { name: roomIdentifier, audio: true, video: false })
			.then((room) => {
				setRoom(room);
				roomRef.current = room;
				room.on('participantConnected', onConnect);
				room.on('participantDisconnected', handleDisconnect);
				room.participants.forEach(onConnect);
				preventCameraStart && cameraOff();
				onLoadEnd && onLoadEnd();
			})
			.catch((e) => {
				console.log(e);
				onError && onError();
			});
	};

	React.useEffect(() => {
		if (isShareScreen && room?.state !== 'disconnected') {
			screenShare()
				.catch(console.error);
			return () => {
				if (displayStream.current && room) {
					const track = displayStream.current?.getVideoTracks()[0];
					track.stop();
					room.localParticipant.unpublishTrack(track);
					room.localParticipant.videoTracks.forEach((publication) => {
						publication.track.stop();
						publication.track.detach();
						room.localParticipant.unpublishTrack(publication.track);
					});
					displayStream.current = undefined;
				}
				if (isCameraOff && room) {
					cameraOn();
				}
			};
		}
	}, [isShareScreen, room]);

	React.useEffect(() => {
		if (!room) return;

		if (isMute) {
			connected.current && unmute();
			muteRef.current = false;
		} else {
			mute();
			muteRef.current = true;
		}
	}, [isMute, room, members]);

	React.useEffect(() => {
		if (!room) return;

		if (isCameraOff) {
			cameraOn();
		} else {
			cameraOff();
		}
	}, [isCameraOff, room]);

	const screenShare = async () => {
		try {
			const mediaDevice = navigator.mediaDevices as any;
			const stream = await mediaDevice.getDisplayMedia();
			displayStream.current = stream;
			const screenTrack = stream.getVideoTracks()[0];
			screenTrack.addEventListener('ended', () => {
				screenTrack.stop();
				room.localParticipant.videoTracks.forEach((publication) => {
					publication.track.stop();
					publication.track.detach();
					room.localParticipant.unpublishTrack(publication.track);
				});
				if (isCameraOff) {
					cameraOn();
				}
				setShareScreen(false);
			});
			cameraOff(true);
			const localVideoTrack = new twilioVideo.current.LocalVideoTrack(stream.getVideoTracks()[0]);
			const publication = await room.localParticipant.publishTrack(localVideoTrack);
			setPublication(publication);
		} catch (err) {
			console.error(err);
			setShareScreen(false);
			if (isCameraOff) {
				cameraOn();
			}
		}
	};

	const mute = React.useCallback(() => {
		room.localParticipant.audioTracks.forEach((publication) => {
			publication.track.disable();
		});
	}, [room]);

	const unmute = React.useCallback(() => {
		room.localParticipant.audioTracks.forEach((publication) => {
			publication.track.enable();
		});
	}, [room]);

	const cameraOn = React.useCallback(() => {
		if (!Array.from(room?.localParticipant.videoTracks.keys() || { length: 0 }).length
			&& room && room.state !== 'disconnected') {
			twilioVideo.current?.createLocalVideoTrack().then((localVideoTrack) => {
				return room.localParticipant.publishTrack(localVideoTrack);
			})
				.then((publication) => {
					setPublication(publication);
				})
				.catch(console.log);
		}
	}, [room]);

	const cameraOff = React.useCallback((withoutDisableShare?: boolean) => {
		if (room) {
			room.localParticipant.videoTracks.forEach((publication) => {
				publication.track.stop();
				publication.track.detach();
				room.localParticipant.unpublishTrack(publication.track);
			});
			!withoutDisableShare && setShareScreen(false);
			setPublication(null);
		}
	}, [room]);

	return (
		<div
			className={`room ${
				isFullscreen && 'fullscreen'} ${
				members.length === 1 ? 'room_one_member' : ''} ${
				isCameraOff || isShareScreen ? '' : 'hide-self'
			}`}
		>
			{
				!isMinimized
					? hasMedia
						? room
							? (
								<RoomMember
									key={room.localParticipant.sid}
									member={room.localParticipant}
									visible={isCameraOff || isShareScreen}
									muted
									isLocal
								/>
							)
							: <div className="loading">Loading</div>
						: <div className="loading">Connect video device!</div>
					: null
			}
			{
				members && members.length > 0
					? members.map((member) => <RoomMember key={member.sid} member={member} />)
					: <div className="loading">Loading...</div>
			}
		</div>
	);
};

export default RoomComponent;
