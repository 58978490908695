import * as React from 'react';

import Colorbox from '@common/react/components/UI/Colorbox/Colorbox';

import '@commonTuna/react/scss/components/avatar.scss';

interface IAvatar {
	firstName: string;
	lastName: string;
	withModal?: boolean;
	color?: string | null;
	src?: string;
	original?: string;
	className?: string;
	imageSkeleton?: string;
}

const Avatar: React.FC<IAvatar> = ({
	src, original, firstName, lastName, className, withModal, color, imageSkeleton,
}) => {
	const [colorBoxIndex, setColorBoxIndex] = React.useState<number | null>(null);
	const [error, setError] = React.useState<boolean>(false);

	const openAvatar = React.useCallback(() => {
		setColorBoxIndex(0);
	}, []);

	const closeAvatar = React.useCallback(() => {
		setColorBoxIndex(null);
	}, []);

	const fileAvatar = [{ file: { src: original || ' ' } }];

	const onError = () => setError(true);

	return (
		<div
			className={`avatar-container ${className || ''}`}
			style={{ backgroundColor: color || '' }}
		>
			{ (src || imageSkeleton) && (!error || (!firstName && !lastName && imageSkeleton))
				? (
					<img
						onError={onError}
						className={`avatar-img ${withModal ? 'may-open' : ''}`}
						onClick={openAvatar}
						src={error ? imageSkeleton : src || imageSkeleton}
						alt="avatar"
						title={`${firstName} ${lastName}`}
					/>
				)
				: (
					<span
						className={`avatar-user-initials ${color ? 'color-filter' : ''}`}
						style={{ color: color || '' }}
						title={`${firstName} ${lastName}`}
					>
						{firstName?.length > 0 ? firstName[0] : ''}
						{lastName?.length > 0 ? lastName[0] : ''}
					</span>
				)
			}
			{withModal && original && !error
				&& <Colorbox files={fileAvatar} defaultIdx={colorBoxIndex} onCancel={closeAvatar} />
			}
		</div>
	);
};

export default Avatar;
