import { Action, Reducer, ActionCreatorsMapObject } from 'redux';

import { AppThunkAction } from '@app/store/index';

/* interface AppointmentInvitesCounters {
	appointmentId: number;
	notAnsweredInvitesCount: number;
	notReadInstructionsCount: number;
} */

export interface CountersState {
	notViewedDocumentsCount: number;
	notAnsweredDiseasesCount: number;
	notAnsweredConsentsCount: number;
	notReadInstructionsCount: number;
	notViewedPrescriptionsCount: number;
	notPaidBillItemsCount: number;

	totalDocumentsCount: number;
	totalDiseasesCount: number;
	totalConsentsCount: number;
	totalInstructionsCount: number;
	totalPrescriptionsCount: number;
	totalBillItemsCount: number;
}

export enum CountersTypeKeys {
	SET_COUNTERS_STATE = 'SET_COUNTERS_STATE',
	UPDATE_COUNTERS = 'UPDATE_COUNTERS'
}

export interface SetCountersState {
	type: CountersTypeKeys.SET_COUNTERS_STATE;
	payload: CountersState;
}

interface UpdateCounters {
	type: CountersTypeKeys.UPDATE_COUNTERS;
	data: object;
}

export type Actions = SetCountersState | UpdateCounters;

export interface CountersActionCreators extends ActionCreatorsMapObject {
	setCountersState: (state: CountersState) => AppThunkAction<Actions>;
	updateCounters: (data: object) => AppThunkAction<Actions>;
}

export const countersActionCreators = {
	setCountersState: (state: CountersState): AppThunkAction<Actions> => (dispatch, getState) => {
		dispatch({ type: CountersTypeKeys.SET_COUNTERS_STATE, payload: { ...state } });
	},
	updateCounters: (data: object): AppThunkAction<Actions> => (dispatch, getState) => {
		dispatch({ type: CountersTypeKeys.UPDATE_COUNTERS, data });
	},
};

const initialState = {
	notViewedDocumentsCount: 0,
	notAnsweredDiseasesCount: 0,
	notAnsweredConsentsCount: 0,
	notReadInstructionsCount: 0,
	notViewedPrescriptionsCount: 0,
	notPaidBillItemsCount: 0,
	totalDocumentsCount: 0,
	totalDiseasesCount: 0,
	totalConsentsCount: 0,
	totalInstructionsCount: 0,
	totalPrescriptionsCount: 0,
	totalBillItemsCount: 0,
};

export const reducer: Reducer<CountersState> = (
	state: CountersState = initialState,
	incomingAction: Action,
) => {
	const action = incomingAction as Actions;
	switch (action.type) {
		case CountersTypeKeys.SET_COUNTERS_STATE:
			return { ...action.payload };
		case CountersTypeKeys.UPDATE_COUNTERS:
			return {
				...state,
				...action.data,
			};
		default:
			return state;
	}
};
