import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';

import ChatMessageForm from '@common/react/components/Chat/ChatMessageForm';
import ChatMessageList from '@common/react/components/Chat/ChatMessageList';
import { Chat, ChatMessage } from '@common/react/components/Chat/Chat';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import useRequest from '@common/react/hooks/useRequest';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

interface Props {
	chat?: Chat;
	chatId: number;
	actionsInPopup?: boolean;
	filesAfterButtons?: boolean;
	getActionPopupContainer?: (node) => HTMLElement;
}

const ChatMessagesWithForm: React.FC<Props> = (props) => {
	const context = useChatSettingsProviderContext();
	const {
		state: {
			requests: { getChat }, storageName, chatStoreSettings, avatarSettings,
		},
	} = context;
	const { getActionCreators } = chatStoreSettings;

	const chats = useSelector((state: any) => state[storageName]?.chats, shallowEqual);
	const [chat, setChat] = React.useState<Chat | undefined>(() => props.chat || chats?.list.find((chat) => chat?.id === props.chatId));
	const user = useSelector((state: any) => state.login.user, shallowEqual);
	const [editMessage, setEdit] = React.useState<ChatMessage | null>(null);
	const [replyMessage, setReplyMessage] = React.useState<ChatMessage | null>(null);

	const dispatch = useDispatch();
	const actions = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);

	const request = useRequest();

	React.useEffect(() => {
		if (!chat && props.chatId) {
			request<Chat>(getChat, { chatId: props.chatId })
				.then((res) => {
					setChat(res);
				});
		}
	}, []);

	React.useEffect(() => {
		setEdit(null);
	}, [props.chatId]);

	if (!user || !props.chatId) {
		return null;
	}

	if (!chat) {
		return <Loader />;
	}

	return <>
		<ChatMessageList
			replyMessage={replyMessage}
			setReplyMessage={setReplyMessage}
			actions={actions}
			chat={chat}
			user={user}
			getUserAvatar={avatarSettings.getUserAvatar}
			setEdit={setEdit}
			editMessage={editMessage}
		/>
		<ChatMessageForm
			chat={chat}
			editMessage={editMessage}
			setEdit={setEdit}
			getUserAvatar={avatarSettings.getUserAvatarAtMention}
			actionsInPopup={props.actionsInPopup}
			filesAfterButtons={props.filesAfterButtons}
			getActionPopupContainer={props.getActionPopupContainer}
		/>
	</>;
};

export default ChatMessagesWithForm;
