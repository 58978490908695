import React from 'react';

import Message from 'antd/lib/message';
import Modal from 'antd/lib/modal';

import { request } from '@app/components/Api';

const DashboardTopAlert: React.FC<{objectId: number}> = ({ objectId }) => {
	const handleClick = () => {
		request('sendConfirmEmail', { id: objectId })
			.then(() => Modal.success({ content: 'The activation email has been successfully sent.' }))
			.catch(Message.error);
	};

	return <div className="dashboard-top-alert alert alert-danger">
		This account is not confirmed.&nbsp;
		<a onClick={handleClick}>Please click here to resend the confirmation email.</a>
	</div>;
};

export default DashboardTopAlert;
