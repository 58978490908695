import React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import emptyCart from '@images/empty-cart.png';

const EmptyCart: React.FC = () => {
	return <div className="empty-cart">
		<div className="empty-cart__img">
			<img src={emptyCart} alt="Empty Cart Icon" />
		</div>
		<h1>You have no items in your shopping cart.</h1>
		<LinkWithPrevLocation to="/specials">
			Let's go buy something!
		</LinkWithPrevLocation>
	</div>;
};

export default EmptyCart;
