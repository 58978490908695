import React from 'react';

const useScrollTo = <T extends HTMLElement, >(
	refreshId: number | string | null,
	prevent?: boolean,
	afterScrollStart?: () => void,
) => {
	const ref = React.useRef<T>(null);

	React.useEffect(() => {
		const scrollContainer = ref.current;

		if (scrollContainer && !prevent) {
			scrollContainer.scrollIntoView({ block: 'end' });
			setTimeout(() => afterScrollStart && afterScrollStart(), 0);
		}
	}, [refreshId]);

	return ref;
};

export default useScrollTo;
