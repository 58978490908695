import * as React from 'react';

import loadable from '@loadable/component';

import { ChatPlugin, EmojiReaction } from '@common/react/components/Chat/Chat';

import ChatReactionComponent from '@common/react/components/Chat/Reactions/ChatReactionComponent';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

const params = {
	fallback: <Loader />,
};

const TextChatMessage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TextChatMessage" */ '@common/react/components/Chat/TextChatMessage')), params);

export const ReactionsPlugin : ChatPlugin = {
	message: {
		render: () => null,
		notification: ({ message, withRemoteId, contacts }) => {
			return (
				<div style={{
					display: 'flex',
					alignItems: 'center',
				}}
				>
					<span style={{ marginRight: '10px' }}>has left reaction</span>
					<TextChatMessage
						className="reaction-emoji"
						emojiSize={22}
						text={message.reaction}
						contacts={[]}
					/>
				</div>
			);
		},
	},
	chatMessageAction: ({ message }) =>
		<ChatReactionComponent message={message} />,
	notificationHandler: (notification, storageName, actions, options) => {
		if (notification.objectType === options.objectType) {
			const chatReaction = notification.data as EmojiReaction;
			if (notification.action === NotificationAction.Add) {
				actions.addReaction(chatReaction.object.chatId, chatReaction.objectId, {
					...chatReaction,
					animate: true,
				}, storageName);
			} else {
				actions.removeReaction(chatReaction.object.chatId, chatReaction.objectId, chatReaction.id, storageName);
			}
		}
	},
	options: {
		objectType: 'ChatEmojiReaction',
	},
};
