import * as React from 'react';

export const defaultChatMessagesError = ({ reload }) => {
	return <div className="chat-messages-error">
		<h3 className="error-message">
			Oops... we couldn't upload the messages,
			<br />
			but our team is already working on it
		</h3>
		<br />
		<button type="button" className="btn btn-default btn-sm" onClick={() => reload()}>
			Click to reload messages
		</button>
	</div>;
};
