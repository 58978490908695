import React from 'react';

import Badge from 'antd/lib/badge';
import loadable from '@loadable/component';

import { generateGUID } from '@common/react/utils/utils';
import { EmojiReaction } from '@common/react/components/Chat/Chat';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

const params = {
	fallback: <Loader />,
};

const TextChatMessage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "TextChatMessage" */ '@common/react/components/Chat/TextChatMessage')), params);

interface ChatReactionProps {
	emojiReaction: EmojiReaction;
	handleReactionClick: (id: number) => void;
	disabled: boolean;
	animate: boolean;
	afterAnimation: () => void;
	count: number;
	username?: string;
}

interface ChatReactionAnimationItemProps {
	duration: number;
	rotate: number;
	radius: number;
	delay: number;
	text: string;
	id: string;
	onTransitionEnd: (id) => void;
}

export const ChatReactionAnimationItem: React.FC<ChatReactionAnimationItemProps> = (props) => {
	const {
		text, delay, radius, rotate, duration, id, onTransitionEnd,
	} = props;
	const [style, setStyle] = React.useState<React.CSSProperties>();

	React.useEffect(() => {
		const timer = setTimeout(() => setStyle({
			transitionDelay: `${delay}s`,
			transform: `translate(${radius * Math.cos(rotate)}px, ${radius * Math.sin(rotate)}px) scale(0)`,
			transitionDuration: `${duration}s`,
		}), 0);
		return () => clearTimeout(timer);
	}, []);

	const handleTransitionEnd = React.useCallback(() => {
		onTransitionEnd(id);
	}, [id]);

	return <TextChatMessage
		style={style}
		onTransitionEnd={handleTransitionEnd}
		className="chat-message-reaction-animation-item"
		text={text}
		contacts={[]}
		emojiSize={22}
		emojiWithoutWrapper
	/>;
};

const ChatReactionAnimation: React.FC<{text, count?: number, onAnimationEnd}> = ({ text, onAnimationEnd, count = 8 }) => {
	const [items, setItems] = React.useState(() => {
		const angle = 360 / (count || 6);
		return Array.from({ length: count })
			.map((_, index) => ({
				rotate: (Math.PI * Math.round((index * angle) + ((2 / 3) * angle * (Math.random() - 0.5)))) / 180,
				radius: Math.round(25 + (3 * Math.random())),
				delay: 0.1 * Math.random(),
				duration: 0.7 + (Math.random() * 0.2),
				id: generateGUID(),
			}));
	});

	const onTransitionEnd = React.useCallback((id) => {
		setItems((items) => {
			const newItems = items.filter((item) => item.id !== id);
			if (!newItems.length) {
				onAnimationEnd();
			}
			return newItems;
		});
	}, []);

	return <>
		{items.map((item) => <ChatReactionAnimationItem
			{...item}
			onTransitionEnd={onTransitionEnd}
			key={item.id}
			text={text}
		/>)}
	</>;
};

const ChatReaction: React.FC<ChatReactionProps> = ({
	emojiReaction, animate, afterAnimation, disabled, username, handleReactionClick, count,
}) => {
	return (
		<div className="chat-message-reaction-wrapper" key={`reaction-${emojiReaction.id}`}>
			{animate ? <ChatReactionAnimation
				text={emojiReaction.reaction}
				onAnimationEnd={afterAnimation}
			/> : null}
			<button
				type="button"
				title={username}
				onClick={() => handleReactionClick(emojiReaction.id)}
				key={`reaction-${emojiReaction.id}`}
				className={`chat-message-reaction ${disabled ? 'disabled' : ''}`}
				data-reaction-id={emojiReaction.id}
				disabled={disabled}
			>
				<TextChatMessage
					text={emojiReaction.reaction}
					contacts={[]}
					emojiSize={22}
					emojiWithoutWrapper
				/>
				{count > 1 ? <Badge color="rgb(210, 220, 230)" count={count} /> : null}
			</button>
		</div>
	);
};

export default ChatReaction;
