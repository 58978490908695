import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';

import { User } from '@app/objects/User';

import { Notification } from '@common/typescript/objects/Notification';
import { BaseUser, BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import { Chat, ChatUser } from '@common/react/components/Chat/Chat';
import { BaseApplicationState } from '@common/react/store';
import { subscribe } from '@common/react/utils/SignalRUtils/SignalRUtils';
import { NotificationAction } from '@common/typescript/objects/NotificationAction';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';
import * as Login from '@common/react/store/Login';
import useRequest from '@common/react/hooks/useRequest';
import { ApplicationStateWithChats, ChatsActionCreators } from '@common/react/components/Chat/Store/Chats';

const notifySound = require('@common/react/audio/notify.ogg');

interface Props<Actions = any> {
	withRemoteId?: boolean;
}

export type Actions = ChatsActionCreators<BaseUserWithAvatar, ApplicationStateWithChats<BaseUserWithAvatar>>;
type LoginActions = Login.LoginActionCreators<User, BaseApplicationState<User>>;

const SignalRChats:React.FC<Props> = (props) => {
	const { withRemoteId } = props;
	const user = useSelector((state: BaseApplicationState<BaseUser>) => state.login.user, shallowEqual);
	const dispatch = useDispatch();

	const context = useChatSettingsProviderContext();

	if (!context?.state) throw 'need ChatSettingsContext';

	const {
		state: {
			notificationTypes,
			plugins,
			requests,
			storageName,
			chatStoreSettings: {
				getActionCreators,
				userUnviewedMessagesCountName,
			},
		},
	} = context;
	const request = useRequest();

	const actions: Actions = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);
	const loginActions: LoginActions = bindActionCreators(Login.getActionCreators(), dispatch);

	const handleNotification = React.useCallback((notification: Notification<BaseUser>) => {
		if (!user) return;
		switch (notification.objectType) {
			case notificationTypes.chatMessage:
				const chatMessage = notification.data;
				const isDelete = notification.action === NotificationAction.Delete;

				if (isDelete) {
					actions.removeMessage(request, requests.getChat, storageName, chatMessage, chatMessage.chatId);
					if (chatMessage.viewed) {
						return;
					}
				}

				if (notification.action === NotificationAction.Update) {
					actions.updateMessageContent(chatMessage, storageName);
					return;
				}

				if (withRemoteId ? chatMessage.remoteId >= 0 : chatMessage.remoteId == null) {
					const isOwnMessage: boolean = withRemoteId ? user?.id === chatMessage.remoteId
						: user?.id === chatMessage.userId;

					if (!isOwnMessage) {
						try {
							!isDelete && new Audio(notifySound).play();
						} catch (e) {
							console.log(e);
						}

						loginActions.updateUser(
							{},
							(user) => ({ [userUnviewedMessagesCountName]: user[userUnviewedMessagesCountName] + (isDelete ? -1 : 1) }),
						);
					}

					notification.action === NotificationAction.Add
					&& actions.addMessage(request, requests.getChat, storageName, chatMessage, !isOwnMessage);
				}
				break;
			case notificationTypes.chatMessageAccess:
				const chatMessageAccess = notification.data;
				if (chatMessageAccess.viewed && chatMessageAccess.chatMessageObject) {
					const userIdName = withRemoteId ? 'remoteId' : 'userId';
					const isCurrentUserViewed = user?.id === chatMessageAccess[userIdName];
					const isViewedCurrentUserMessage = user?.id === chatMessageAccess.chatMessageObject[userIdName];

					if ((isCurrentUserViewed && !isViewedCurrentUserMessage) || (!isCurrentUserViewed && isViewedCurrentUserMessage)) {
						actions.updateMessageViewed(
							chatMessageAccess,
							chatMessageAccess.chatMessageObject.chatId,
							chatMessageAccess.chatMessageObject.id,
							chatMessageAccess.viewed,
							storageName,
							!isCurrentUserViewed,
						);
					}

					if (isCurrentUserViewed && !isViewedCurrentUserMessage) {
						actions.changeChatCounter(chatMessageAccess.chatMessageObject.chatId, -1, storageName);
						loginActions.updateUser(
							{},
							(user) => ({
								[userUnviewedMessagesCountName]: user[userUnviewedMessagesCountName] < 0
									? 0 : user[userUnviewedMessagesCountName] - 1,
							}),
						);
					}
				}
				break;
			case notificationTypes.chat:
				if (notification.action === NotificationAction.Add) {
					actions.addChat(notification.data as Chat, storageName);
				} else if (notification.action === NotificationAction.Update) {
					actions.updateChat(notification.data as Chat, storageName);
				}
				break;
			case notificationTypes.updateChatCounterNotification:
				if (notification.action === NotificationAction.Update) {
					const chat = notification.data;

					actions.updateChatCounter(chat.id, chat.unviewedMessagesCount, storageName);
				}
				break;
			case notificationTypes.updateUserMessagesCountNotification:
				if (user?.id === notification.data?.id) {
					const unViewedMessagesCount = notification.data?.unviewedMessagesCount;
					loginActions.updateUser({ [userUnviewedMessagesCountName]: unViewedMessagesCount > 0 ? unViewedMessagesCount : 0 });
				}
				break;
			case notificationTypes.chatUser:
				const chatUser = notification.data as ChatUser;
				const remoteId = (chatUser as any).remoteId;
				if (notification.action === NotificationAction.Add) {
					chatUser.user = {
						...chatUser.user,
						remoteId,
					} as BaseUserWithAvatar;
					actions.addUserToChat(chatUser, storageName);
				} else if (notification.action === NotificationAction.Delete) {
					if (withRemoteId ? remoteId === user?.id : (notification.data as ChatUser).userId === user?.id) {
						if (withRemoteId) {
							actions.removeUserFromChat(chatUser, storageName);
						}
						actions.removeChat(chatUser.chatId, storageName);
					} else {
						actions.removeUserFromChat(chatUser, storageName);
					}
				}
				break;
				// no default
		}
		Object.keys(plugins)
			.forEach((key) => {
				plugins[key]?.notificationHandler?.(notification, storageName, actions, plugins[key]?.options);
			});
	}, [user?.id, request]);

	React.useEffect(subscribe(handleNotification), [user?.id]);

	return <></>;
};

export default SignalRChats;
