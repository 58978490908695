import { BaseUser } from '@common/react/objects/BaseUser';
import { Nullable } from '@common/typescript/objects/Nullable';

import { Gender } from '@commonTuna/react/objects/Enums';

import { Insurance } from '@app/objects/Insurance';
import { DrivingLicense } from '@app/objects/DrivingLicense';
import { Suffix } from '@app/objects/Suffix';
import { MarriageStatus } from '@app/objects/MarriageStatus';
import { PatientLocation } from '@app/objects/PatientLocation';

export enum UserRole {
	Admin = 1,
	User = 2,
	Support = 3
}

export const UserRoleNames = {
	[UserRole.Admin]: 'Admin',
	[UserRole.User]: 'User',
	[UserRole.Support]: 'Support',
};

export interface User extends BaseUser {
	avatar: string;
	originalAvatar: string;

	role: UserRole;

	suffix: Suffix;
	firstName: string;
	lastName: string;
	email: string;
	birthDate: Nullable<number>;
	gender: Nullable<Gender>;
	phoneNumber: string;

	marriageStatus: MarriageStatus;

	emergencyContactName: string;
	emergencyContactPhone: string;
	emergencyContactRelation: string;

	address: string;
	city: string;
	region: string;
	zip: string;

	insurances: Array<Insurance>;
	patientLocations: Array<PatientLocation>;

	drivingLicenses: Array<DrivingLicense>;

	allowEmail: boolean;
	allowSms: boolean;
	allowCalls: boolean;
	allowPush: boolean;
	agreementForSignUp: boolean;

	emailConfirmed: boolean;

	enableSounds: boolean;
	browserNotifications: boolean;
	completedRegistration: boolean;
	color: string | null;
	lastLoggedIn?: number;
	time: number;

	baseUtcOffset: string;

	ethnicityId: Nullable<number>;
}

export interface RegistrationUser extends User {
	password: string;
	repeatPassword: string;
	captcha?: string;
}
