import * as React from 'react';

import once from 'lodash/once';

export interface MenuStateProviderContextState {
	open: boolean;
	mounted: boolean;
}

export interface MenuStateProviderContext {
	state: MenuStateProviderContextState;
	actions: {setState};
}

export const createMenuStateProviderContext = once(() => React.createContext({} as MenuStateProviderContext));

export const useMenuStateProviderContext: () => MenuStateProviderContext = () => React.useContext(createMenuStateProviderContext());

export const MenuStateProvider: React.FC = ({ children }) => {
	const ItemContext = createMenuStateProviderContext();

	const [state, setState] = React.useState<MenuStateProviderContextState>({ open: false, mounted: false });

	const value = {
		state,
		actions: {
			setState,
		},
	};

	return (
		<ItemContext.Provider value={value}>
			{children}
		</ItemContext.Provider>
	);
};
