import React from 'react';

interface Props {
	address: string;
	className?: string;
}

const LocationLink: React.FC<Props> = ({ address, children, className }) => {
	const link = React.useMemo(() => {
		let res = `https://maps.google.com/maps/search/?api=1&query=${address}`;
		if (typeof window !== 'undefined') {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				res = `maps://maps.google.com/maps/search/?api=1&query=${address}`;
			}
		}
		return res;
	}, [address]);

	return <a
		className={className}
		target="_blank"
		href={link}
		rel="noreferrer"
	>
		{children}
	</a>;
};

export default LocationLink;
