import * as React from 'react';

import AutoComplete, { AutoCompleteProps } from 'antd/lib/auto-complete';
import { SelectValue } from 'antd/es/select';
import { DefaultOptionType } from 'antd/lib/select';
import Empty from 'antd/lib/empty';

import { request } from '@common/react/components/Api';
import { Rows } from '@common/typescript/objects/List';
import { debounce } from '@common/typescript/Utils';
import { getPopupContainer as getDefaultPopupContainer } from '@common/react/components/Utils/Utils';
import { WithId } from '@common/typescript/objects/WithId';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { isFunction, isUndefined } from '@common/react/utils/guards';
import { Nullable } from '@common/typescript/objects/Nullable';
import SimpleLoader from '@common/react/components/UI/SimpleLoader/SimpleLoader';

export const Option = AutoComplete.Option;

interface AutocompleteState {
	items: Array<OptionType>;
	value: string;
	isLoading: boolean;
	loadedForParams: Nullable<object>;
	reload: boolean;
}

export interface OptionType extends Omit<DefaultOptionType, 'item'> {
	item?: any;
	label: React.ReactNode;
}

type RenderTitleFunc<T> = (item: T) => React.ReactNode;

export interface AutocompleteProps<T extends WithId = any, P = object> extends Pick<AutoCompleteProps, 'children'> {
	type: string;
	onSelect: (value: string, option?: any) => void;
	onChange?: (value: string) => void;
	renderOption?: (item: T) => OptionType;
	renderTitle?: keyof T | RenderTitleFunc<T>;
	params?: BaseParams;
	paramName?: string;
	min?: number;
	value: string;
	isClear?: boolean;
	antdProps?: AutoCompleteProps & P;
	loadOnFocus?: boolean;
	disabled?: boolean;
	placeholder?: string;
	onExtraRender?: (state: AutocompleteState, props: AutocompleteProps<T>) => void;
	loaderMarkup?: JSX.Element;
	updateAfterSelect?: boolean;
	transformValue?: (value: string) => string;
	shouldSelectMatch?: boolean;
	additionalOptions?: Array<OptionType>;
	getValueFromOption?: (option: OptionType) => any;
	style?: React.CSSProperties;
	className?: string;
	resetValueOnBlur?: boolean; // if props.value is empty
	autocompleteRef?: React.Ref<any>;
	getPopupContainer?: (node) => HTMLElement;
	getOptions?: (options: Array<OptionType>) => Array<OptionType>;
	onLoad?: () => void;
	emptyText?: string;
}

const getValueFromOptionDefault = (option: OptionType) => {
	return option.item?.id || option.props?.item?.id;
};

export default class Autocomplete<T extends WithId = any, P = object, Props extends AutocompleteProps<T, P> = AutocompleteProps<T, P>>
	extends React.Component<Props, AutocompleteState> {
	public static defaultProps: Partial<AutocompleteProps> = {
		value: '',
		loaderMarkup: <SimpleLoader />,
		paramName: 'text',
		shouldSelectMatch: true,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			items: [],
			value: this.props.value || '',
			isLoading: false,
			loadedForParams: null,
			reload: false,
		};

		this.loadItems = debounce(this.loadItems.bind(this), 300);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.defaultRender = this.defaultRender.bind(this);
		this.selectMatchingOption = this.selectMatchingOption.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	defaultRender(item: T): OptionType {
		const { renderTitle } = this.props;

		let title;

		if (isFunction(renderTitle)) {
			title = renderTitle(item);
		} else {
			title = `${item[(renderTitle || 'name') as keyof T]}`;
		}

		return {
			item,
			title: typeof title === 'string' ? title : undefined,
			key: item.id,
			value: title,
			label: title,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps: AutocompleteProps) {
		if (nextProps.value !== this.props.value) {
			this.setState({
				value: nextProps.value,
			});
		}
	}

	shouldComponentUpdate(nextProps: AutocompleteProps, nextState: AutocompleteState) {
		return nextProps.value !== this.props.value
			|| nextProps.disabled !== this.props.disabled
			|| nextProps.placeholder !== this.props.placeholder
			|| nextState.value !== this.state.value
			|| nextState.items !== this.state.items
			|| nextState.isLoading !== this.state.isLoading;
	}

	onChange = (value: SelectValue) => {
		this.setState({
			value: value as string,
		});

		this.props.onChange && this.props.onChange(value as string);
	}

	onSelect = (value, option) => {
		const selectedValue = this.props.getValueFromOption ? this.props.getValueFromOption(option)
			: getValueFromOptionDefault(option);

		this.props.onSelect && this.props.onSelect(selectedValue, option);

		if (this.props.isClear) {
			setTimeout(() => {
				this.setState({
					value: '',
				});
				this.loadItems('');
			}, 0);
		} else {
			setTimeout(() => {
				this.setState({
					items: [],
					reload: true,
				});
			}, 0);

			if (this.props.updateAfterSelect) {
				setTimeout(() => this.loadItems(this.state.value), 0);
			}
		}
	}

	loadItems(value: string) {
		const {
			renderOption, type, params, paramName, transformValue, shouldSelectMatch,
		} = this.props;

		const newParams = {
			...params,
			[paramName!]: transformValue ? transformValue(value) : value,
		};

		if (this.state.reload || (JSON.stringify(newParams) !== JSON.stringify(this.state.loadedForParams))) {
			this.setState({
				isLoading: true,
				reload: false,
			});

			request<Rows<T>, BaseUser, BaseApplicationState<BaseUser>>(type, newParams).then((response) => {
				const items = this.props.additionalOptions?.length
					? this.props.additionalOptions.concat(response.list.map(renderOption || this.defaultRender))
					: response.list.map(renderOption || this.defaultRender);

				this.setState({
					isLoading: false,
					loadedForParams: newParams,
					items: items.length || !this.props.emptyText ? items : [{
						disabled: true,
						label: <Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={this.props.emptyText}
							style={{ marginBlock: 8 }}
							imageStyle={{ height: 35 }}
						/>,
						item: {},
					}],
				});
				this.props.onLoad && this.props.onLoad();

				shouldSelectMatch && this.selectMatchingOption(items, value);
			}).catch(() => {
				this.setState({
					isLoading: false,
					items: [],
				});
			});
		}
	}

	selectMatchingOption(items, value: string) {
		if (items.length === 1 && value) {
			const item = this.state.items[0] as any;

			const condition: boolean = item.value && typeof item.value === 'string'
				&& item.value.toLowerCase() === value.toLowerCase();

			if (condition) {
				this.setState({
					value: item.value,
				});

				this.onSelect(item.key, { props: item, ...item });
			}
		}
	}

	onSearchHandler(value: string) {
		const { min = 3, loadOnFocus } = this.props;

		if (value.length >= min || loadOnFocus) {
			this.loadItems(value);
		} else {
			this.setState({
				items: [],
			});
		}
	}

	onFocus = () => {
		if (this.state.value === '') {
			this.onSearchHandler('');
		}
	}

	onBlur(e) {
		if (this.props.resetValueOnBlur && this.props.value !== undefined) {
			this.setState({ value: this.props.value });
		}

		if (this.props.antdProps?.onBlur) {
			this.props.antdProps.onBlur(e);
		}
	}

	render(): JSX.Element {
		const {
			disabled, onExtraRender, loadOnFocus, loaderMarkup, placeholder, antdProps, children, getPopupContainer = getDefaultPopupContainer,
		} = this.props;

		return <>
			<div
				style={this.props.style}
				className={`autocomplete-component ${this.props.className} ${disabled ? 'autocomplete-component_disabled' : ''}`}
			>
				{this.state.isLoading && <div className="autocomplete-component__loader">{loaderMarkup}</div>}
				<AutoComplete
					ref={this.props.autocompleteRef}
					onChange={this.onChange}
					options={this.props.getOptions ? this.props.getOptions(this.state.items) : this.state.items}
					allowClear
					onSelect={this.onSelect}
					onFocus={loadOnFocus ? this.onFocus : undefined}
					onSearch={this.onSearchHandler}
					value={this.state.value}
					disabled={disabled}
					placeholder={isUndefined(placeholder) ? 'Start typing for search...' : placeholder}
					getPopupContainer={getPopupContainer}
					{...antdProps}
					onBlur={this.onBlur}
				>
					{children}
				</AutoComplete>
			</div>
			{onExtraRender && onExtraRender(this.state, this.props)}
		</>;
	}
}
