import * as React from 'react';
import { NavLink } from 'react-router-dom';

import Tag from 'antd/lib/tag';

import { Product } from '@app/objects/Product/Product';

const ProductTag: React.FC<{product: Product}> = (props) => {
	const product = props.product;
	return (<>
		{product.path
			? <NavLink to={`/product/${product.path}`}>
				<Tag color="blue" key={product.id} style={{ fontSize: '16px' }}>
					{product.name}
				</Tag>
			</NavLink>
			: <Tag color="blue" key={product.id} style={{ fontSize: '16px' }}>
				{product.name}
			</Tag>
		}
	</>);
};

export default ProductTag;
