import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';

import { BaseApplicationState } from '@common/react/store';
import { BaseUserWithAvatar } from '@common/typescript/objects/BaseUser';
import { ChatMessage, EmojiReaction } from '@common/react/components/Chat/Chat';
import ChatReaction from '@common/react/components/Chat/Reactions/ChatReaction';
import ChatReactionButton from '@common/react/components/Chat/Reactions/ChatReactionButton';
import useRequest from '@common/react/hooks/useRequest';
import { useChatSettingsProviderContext } from '@common/react/components/Chat/ChatSettingsProvider';

interface ChatReactionComponentProps {
	message: ChatMessage;
}

interface ReactionsData {
	solvedReactions: Array<Array<EmojiReaction>>;
	currentUserReaction?: string;
	currentUserReactionId?: number;
}

const ChatReactionComponent: React.FC<ChatReactionComponentProps> = ({ message }) => {
	const request = useRequest();

	const { state: { requests, storageName, chatStoreSettings: { getActionCreators, getCurrentChat } } } = useChatSettingsProviderContext();
	const currentUser = useSelector((state: BaseApplicationState<BaseUserWithAvatar>) => state.login.user);

	const dispatch = useDispatch();
	const { addReaction, removeReaction } = React.useMemo(() => bindActionCreators(getActionCreators(), dispatch), []);

	const [isOpen, setIsOpen] = React.useState<boolean>(() => false);
	const { solvedReactions, currentUserReaction, currentUserReactionId }: ReactionsData = React.useMemo(() => {
		let currentUserReaction: string = '';
		let currentUserReactionId: number = -1;
		const obj = {};
		message.emojiReactions?.forEach((item) => {
			if (item.userId === currentUser?.id) {
				currentUserReaction = item.reaction;
				currentUserReactionId = item.id;
			}
			if (obj[item.reaction]) {
				obj[item.reaction].push(item);
			} else {
				obj[item.reaction] = [item];
			}
		});
		return {
			solvedReactions: Object.values(obj),
			currentUserReaction: currentUserReaction || undefined,
			currentUserReactionId,
		};
	}, [message.emojiReactions]);

	const handleReactionClick = (id: number) => {
		requests.chatEmojiReactionMessage
		&& request<EmojiReaction>(requests.chatEmojiReactionMessage, { id, deleted: true });
		removeReaction(message.chatId, message.id, id, storageName);
	};

	const handleReactionSelect = (emoji) => {
		requests.chatEmojiReactionMessage
		&& request<EmojiReaction>(requests.chatEmojiReactionMessage, { objectId: message.id, reaction: emoji.native, deleted: false })
			.then((emojiReaction) => addReaction(message.chatId, message.id, { ...emojiReaction, animate: true }, storageName));
		setIsOpen(false);
	};

	const handleReactionOpenChange = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	const updateReaction = (reaction) => {
		addReaction(message.chatId, message.id, reaction);
	};

	return (
		currentUser
		&& <div className="chat-message-reactions">
			{
				solvedReactions.map((emojiReactions) => {
					const count = emojiReactions.length;
					const emojiReaction = emojiReactions?.[0];
					const username = count > 1 ? ''
						: emojiReaction.user && emojiReaction.userId !== currentUser.id
							? `${emojiReaction.user.firstName} ${emojiReaction.user.lastName}`
							: 'Me';
					const animatedReactions = emojiReactions.filter((item) => item.animate);
					return <ChatReaction
						afterAnimation={() => {
							animatedReactions.forEach((reaction) => {
								updateReaction({ ...reaction, animate: false });
							});
						}}
						animate={animatedReactions.length > 0}
						key={`reaction-${emojiReactions?.[0].reaction}`}
						emojiReaction={emojiReactions?.[0]}
						disabled={currentUserReaction !== emojiReaction?.reaction}
						username={username}
						handleReactionClick={() => currentUserReactionId && handleReactionClick(currentUserReactionId)}
						count={emojiReactions.length}
					/>;
				})
			}
			{
				currentUser.id !== message.userId
				&& (message.emojiReactions || [])?.map((emojiReaction) => emojiReaction.userId).indexOf(currentUser.id) === -1
				&& <ChatReactionButton
					isOpen={isOpen}
					getPopupContainer={(node) => node.closest('.chat-message-list-component__item')}
					handleReactionSelect={handleReactionSelect}
					handleReactionOpenChange={handleReactionOpenChange}
				/>
			}
		</div>
	);
};

export default ChatReactionComponent;
