import React from 'react';

import { PreviewInfo } from '@common/react/objects/LinkPreview';
import '@common/react/scss/components/linkPreview.scss';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

interface LinkPreviewProps {
	previewInfo?: PreviewInfo;
}

export const regularLink = (link) => `<a href="${link}" target="_blank">${link}</a>`;

export const processLink = (text: string) => {
	const regex = new RegExp(linkRegex);
	return text.match(regex) ?? [];
};

// eslint-disable-next-line max-len
export const linkRegex = /(((?:(https?)(?::\/\/))((?:w{3}\.)?(?:[a-z0-9\.-]+)(:[0-9]+)?))((?:\/[a-z0-9\.\-_]*)*)(((\?|&amp;)(?:[a-z0-9%_\-\+]+)=(?:[a-z0-9\-_%\+\.]+))*)?)\n?/gmi;

const LinkPreviewComponent: React.FC<LinkPreviewProps> = ({ previewInfo }) => {
	const [showImage, setShowImage] = React.useState<boolean>(true);

	const handleError: React.ReactEventHandler<HTMLImageElement> = React.useCallback((e) => {
		setShowImage(false);
	}, []);

	return (
		previewInfo
			? !previewInfo.isImage
				? previewInfo.isLoaded && previewInfo.title
					? <div className="preview-link">
						<a href={previewInfo.link} target="_blank" className="clearfix preview-link__colorbox" rel="noreferrer">
							{
								showImage && previewInfo.image && <div className="pull-left preview-link__image">
									<img src={previewInfo.image} alt={previewInfo.title} onError={handleError} />
								</div>
							}
							<div className="preview-link__content">
								<i className="fa fa-external-link preview-link__icon" aria-hidden="true" />
								<div className="preview-link__title">
									{previewInfo.title}
								</div>
								<div className="preview-link__description">
									{previewInfo.description}
								</div>
							</div>
						</a>
					</div>
					: null
				: <div className="preview-link">
					<a href={previewInfo.link} target="_blank" className="clearfix" rel="noreferrer">
						<div className="pull-left preview-link__image">
							<img src={previewInfo.image} alt={previewInfo.title} />
						</div>
					</a>
				</div>
			: <Loader />
	);
};

export default LinkPreviewComponent;
