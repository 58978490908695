import * as React from 'react';

import Loading from '@common/react/components/Core/LoadingProvider/Loader';

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	imageHiddenClassName?: string;
	loaderContainerClassName?: string;
}

const ImageWithLoader: React.FC<Props> = (props) => {
	const {
		imageHiddenClassName = 'hide-colorbox-image',
		loaderContainerClassName = 'colorbox__loader',
		...rest
	} = props;
	const [loading, setLoading] = React.useState(true);
	const handleLoad = (e) => {
		props.onLoad && props.onLoad(e);
		setLoading(false);
	};

	const handleError = (e) => {
		props.onError && props.onError(e);
		setLoading(false);
	};

	return <>
		<img
			{...rest}
			className={`${loading ? 'hide-colorbox-image' : ''} ${props.className || ''}`}
			onError={handleError}
			onLoad={handleLoad}
			alt={props.alt}
		/>
		{loading ? <div className={loaderContainerClassName}>
			<Loading />
		</div> : null}
	</>;
};

export default ImageWithLoader;
