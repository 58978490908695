import {BaseUser} from '@common/typescript/objects/BaseUser';

export interface File {
	file: FileInterface;
}

export interface FileInterface<TUser extends BaseUser = BaseUser> {
	deleted: boolean;
	extension: string;
	fileType: FileType;
	id: number;
	name: string;
	objectId: number;
	size: number;
	src: string;
	thumb: string;
	time: number;
	user?: TUser;
	userId: number;
	_type: string;
}

export enum FileType {
	Any = 0,
	File = 1,
	Avatar = 2
}